import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="toggle-table-rows"
export default class extends Controller {
    connect() {
        console.log('toggle table rows controller connected')
    }

    toggleRows(event) {
        console.log('toggle table rows')
        event.preventDefault()
        const targetClass = event.currentTarget.dataset.toggleTableRowsButton
        const icon = event.currentTarget.querySelector('i')
        const parentRow = event.currentTarget.closest('tr')
        console.log(targetClass)
        console.log(document.querySelectorAll(`.${targetClass}`))
        document.querySelectorAll(`.${targetClass}`).forEach((row) => {
            if (row.style.display === 'none') {
                this.showRow(row, icon)
                this.applyBackgroundColor(parentRow)
            } else {
                this.hideRow(row, icon)
                this.removeBackgroundColor(parentRow)
                this.hideNestedRows(row)
            }
        })
    }

    showRow(row, icon) {
        row.style.display = ''
        icon.classList.remove('fa-square-plus')
        icon.classList.add('fa-square-minus')
    }

    hideRow(row, icon) {
        row.style.display = 'none'
        icon.classList.remove('fa-square-minus')
        icon.classList.add('fa-square-plus')
    }

    hideNestedRows(row) {
        // Trouver tous les éléments imbriqués qui sont actuellement visibles et les cacher
        row.querySelectorAll('.fa-square-minus').forEach((nestedIcon) => {
            const nestedTargetClass =
                nestedIcon.closest('span').dataset.toggleTableRowsButton
            document
                .querySelectorAll(`.${nestedTargetClass}`)
                .forEach((nestedRow) => {
                    this.hideRow(nestedRow, nestedIcon)
                })
        })
    }

    applyBackgroundColor(row) {
        const colorClass = row.dataset.colorBg
        if (colorClass) {
            row.classList.add(colorClass)
        }
    }

    removeBackgroundColor(row) {
        const colorClass = row.dataset.colorBg
        if (colorClass) {
            row.classList.remove(colorClass)
        }
    }

    collapseAll(event) {
        event.preventDefault()
        console.log('Collapsing all rows')
        document
            .querySelectorAll('[data-toggle-table-rows-button]')
            .forEach((button) => {
                const targetClass = button.dataset.toggleTableRowsButton
                const icon = button.querySelector('i')
                const parentRow = button.closest('tr') // Trouver la ligne parente

                document.querySelectorAll(`.${targetClass}`).forEach((row) => {
                    if (row.style.display !== 'none') {
                        this.hideRow(row, icon)
                        this.removeBackgroundColor(parentRow) // Appliquer le retrait de la couleur de fond
                    }
                })
            })
    }

    expandAll(event) {
        event.preventDefault()
        console.log('Expanding all rows')
        document
            .querySelectorAll('[data-toggle-table-rows-button]')
            .forEach((button) => {
                const targetClass = button.dataset.toggleTableRowsButton
                const icon = button.querySelector('i')
                const parentRow = button.closest('tr') // Trouver la ligne parente

                document.querySelectorAll(`.${targetClass}`).forEach((row) => {
                    if (row.style.display === 'none') {
                        this.showRow(row, icon)
                        this.applyBackgroundColor(parentRow) // Appliquer la couleur de fond
                    }
                })
            })
    }
}
