// locale_controller.js
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { locale: String }

  connect() {
    console.log('Locale controller connected')
  }

  change(event) {
    event.preventDefault()
    const newLocale = event.currentTarget.getAttribute('data-locale-value')
    const currentPath = window.location.pathname.replace(/^\/[a-z]{2}\//, '/')
    const newUrl = `/${newLocale}${currentPath}`
    let url = `/set_temporary_locale`
    // if (document.body.dataset.locale) {
    //     url = `/${document.body.dataset.locale}${url}`
    // }
    fetch(url, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ locale: newLocale }),
    }).then((response) => {
      if (response.ok) {
        window.history.pushState({}, '', newUrl)
        window.location.reload()
      }
    })
  }
}
