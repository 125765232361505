import { Controller } from '@hotwired/stimulus'
import Highcharts from 'highcharts'

// Connects to data-controller="chart-bcg"
export default class extends Controller {
    connect() {
        console.log('Highcharts BCG matrix connected')
        this.loadBCGMatrix()
    }

    loadBCGMatrix() {
        console.log('loadBCGMatrix()')
        const bcgMatrixElement = document.getElementById('chart-bcg-matrix')
        console.log('bcgMatrixElement', bcgMatrixElement)
        const bcgData = JSON.parse(bcgMatrixElement.dataset.bcgMatrix)
        console.log('bcgData', bcgData)

        const formattedData = bcgData.map((item) => {
            // Convertit les centimes en euros et arrondit au deuxième chiffre après la virgule
            const euros = Number(item.data.x)

            console.log(
                'Item:',
                item.name,
                'Euros:',
                euros,
                'Quantity:',
                item.data.y
            )

            return {
                x: euros, // Valeur en euros
                y: Number(item.data.y), // Quantité vendue
                name: item.name, // Le nom de l'article
            }
        })

        console.log('Formatted data for Highcharts:', formattedData)

        Highcharts.chart('chart-bcg-matrix', {
            chart: {
                type: 'scatter',
                zoomType: 'xy',
            },
            title: {
                text: 'Matrice BCG',
            },
            xAxis: {
                title: {
                    text: 'Part de marché relative (€)',
                },
                startOnTick: true,
                endOnTick: true,
                showLastLabel: true,
                type: 'logarithmic', // Si nécessaire, pour de meilleures visualisations
            },
            yAxis: {
                title: {
                    text: 'Quantité',
                },
            },
            tooltip: {
                formatter: function () {
                    return `<b>${this.point.name}</b><br>Part de marché: ${this.x.toLocaleString()} €<br>Quantité: ${this.y}`
                },
            },
            plotOptions: {
                scatter: {
                    marker: {
                        radius: 5,
                    },
                },
            },
            series: [
                {
                    name: 'Produits',
                    data: formattedData,
                },
            ],
            credits: {
                enabled: false,
            },
        })
    }
}
