import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="supplier-delivery-options"
// Used in supplier_restaurant_deliveries/new.html.erb
export default class extends Controller {
    connect() {}

    select(event) {
        const selectedDay = event.currentTarget.dataset.check
        const orderDayInput = event.currentTarget
            .closest('tr')
            .querySelector('[data-list]')
        if (orderDayInput.disabled === true) {
            orderDayInput.disabled = false
        } else {
            orderDayInput.disabled = true
            orderDayInput.value = ''
        }
    }
}
