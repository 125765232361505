import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="file-upload"
export default class extends Controller {
  static targets = ["fileInput", "error"]
  validateSize(event) {
    const fileInput = event.target;
    const file = fileInput.files[0];
    const maxSize = parseInt(fileInput.dataset.maxSize, 10);
    console.log("maxSize", maxSize);
    const maxSizeInBytes = maxSize * 1024 * 1024; // 5 * 1024 - 204 = 5MB

    if (file && file.size > maxSizeInBytes) {
      this.showError();
      fileInput.value = "";
    } else {
      this.hideError();
    }
  }

  showError() {
    console.log("show error");
    const errorElement = this.element.querySelector('#file-size-error');
    errorElement.classList.remove("d-none");
  }

  hideError() {
    console.log("hide error");
    const errorElement = this.element.querySelector('#file-size-error');
    errorElement.classList.add("d-none");
  }
}
