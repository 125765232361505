import { Controller } from '@hotwired/stimulus'
import chartkick from 'chartkick'

// Connects to data-controller="date-range"
export default class extends Controller {
    static targets = [
        'date',
        'chart',
        'supplier',
        'purchaseTable',
        'category',
        'hecto',
    ]

    async refreshChart(event) {
        event.preventDefault()

        const dates = this.dateTarget.value
        const [startDate, endDate] = dates.split(' to ')
        const supplierId = this.supplierTarget.value
        const categoryId = this.categoryTarget.value
        const hecto = this.hectoTarget.checked
        console.log(hecto)
        const params = new URLSearchParams({
            start_date: startDate,
            end_date: endDate,
            supplier_id: supplierId,
            category_id: categoryId,
            hecto: hecto,
        })
        let url_chart = `/refresh_chart?${params.toString()}`
        if (document.body.dataset.locale) {
          url_chart = `/${document.body.dataset.locale}${url_chart}`
        }
        let url_table = `/refresh_table?${params.toString()}`
        if (document.body.dataset.locale) {
          url_table = `/${document.body.dataset.locale}${url_table}`
        }

        try {
            const [chartData, tableData] = await Promise.all([

                fetch(url_chart).then((response) =>
                    response.json()
                ),
                fetch(url_table).then((response) =>
                    response.text()
                ),
            ])

            this.updateChart(chartData)
            this.updateTable(tableData)
        } catch (error) {
            console.error('Error refreshing data:', error)
        }
    }

    updateChart(data) {
        const chart = chartkick.charts['chart-1']
        chart.updateData(data)
    }

    updateTable(data) {
        this.purchaseTableTarget.innerHTML = data
    }
}
