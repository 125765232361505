// app/javascript/controllers/flatpickr_controller.js
import { Controller } from '@hotwired/stimulus'
import flatpickr from 'flatpickr'
import { I18n } from 'i18n-js'
import translations from '../translations.json'

const i18n = new I18n(translations)
const userLocale = document
  .querySelector("meta[name='user-locale']")
  .getAttribute('content')
i18n.locale = userLocale

// Connects to data-controller="flatpickr"
export default class extends Controller {
  static targets = [
    'date',
    'supplier',
    'restaurant',
    'orderTitle',
    'holidayWarning',
    'autoOrder'
  ]

  connect() {
    this.datepicker = flatpickr(this.dateTarget, {
      inline: true,
      showMonths: 1,
      appendTo: document.getElementById('flatpickr-container'),
      locale: {
        firstDayOfWeek: 1,
      },
    })
    this.toggleDateField()
    this.toggleFlatpickrBlock()

    if (this.hasExistingOrder()) {
      this.changeChoiceOfRestaurant(true)
    } else {
      this.changeChoiceOfRestaurant(false)
    }
  }


  hasExistingOrder() {
    const hasOrder = this.supplierTarget.dataset.orderId !== ""
    return hasOrder
  }

  toggleFlatpickrBlock() {
    const flatpickrBlock = document.getElementById('flatpickr-block')
    const autoBox = document.getElementById('auto-box')
    if (this.autoOrderTarget.checked) {
      flatpickrBlock.classList.add('d-none')
      autoBox.classList.remove('d-none')
      this.holidayWarningTarget.innerHTML = ''
    } else {
      flatpickrBlock.classList.remove('d-none')
      autoBox.classList.add('d-none')
    }
  }

  changeChoiceOfRestaurant(isEdit = false) {
    const restaurantValue = this.restaurantTarget.value
    this.datepicker.clear()
    this.datepicker.set('enable', [])
    if (restaurantValue === '') {
      this.supplierTarget.disabled = true
    } else {
      if (this.supplierTarget.disabled === true) {
        this.supplierTarget.disabled = false
      }
      this.updateSupplierList(isEdit)
      this.updateTitleOfOrder()
    }
  }

  updateSupplierList(isEdit = false) {
    const restaurantId = this.restaurantTarget.value
    let url = `/accessible_suppliers?restaurant_id=${restaurantId}`
    if (document.body.dataset.locale) {
      url = `/${document.body.dataset.locale}${url}`
    }


    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        // Trier les fournisseurs par nom et mettre les noms en majuscules
        data.sort((a, b) => a.name.localeCompare(b.name))
        this.supplierTarget.innerHTML = ''

        if (!this.hasExistingOrder()) {
          const blankOption = document.createElement('option')
          blankOption.value = ''
          blankOption.text = '-- Select Supplier --'
          blankOption.setAttribute('disabled', 'disabled')
          blankOption.setAttribute('selected', 'selected')
          this.supplierTarget.add(blankOption)
        }

        data.forEach((supplier) => {
          const option = document.createElement('option')
          option.value = supplier.id
          option.text = supplier.name.toUpperCase()
          this.supplierTarget.add(option)
        })

        if (isEdit && this.hasExistingOrder()) {
          this.supplierTarget.value = this.supplierTarget.dataset.supplierId
        }
      })
  }


  toggleDateField() {
    if (this.autoOrderTarget.checked) {
      this.dateTarget.disabled = true
      this.dateTarget.value = ''
      this.datepicker.clear()
    } else {
      this.dateTarget.disabled = false
    }
  }

  changeSupplierChoice() {
    const supplierId = this.supplierTarget.value
    const restaurantId = this.restaurantTarget.value
    let url = `/supplier_restaurant_deliveries/delivery_dates?supplier_id=${supplierId}&restaurant_id=${restaurantId}`
    if (document.body.dataset.locale) {
      url = `/${document.body.dataset.locale}${url}`
    }
    if (this.supplierTarget.value) {
      fetch(url)
        .then((response) => response.json())
        .then((dates) => {
          this.datepicker.set('enable', dates)
          this.datepicker.clear()
          this.updateTitleOfOrder()
        })
    }
  }

  changeDeliveryDate() {
    const nbDaysAroundHolidayDays = parseInt(this.holidayWarningTarget.dataset.daysCheckAroundHolidayDay)
    const originalSelectedDate = new Date(this.dateTarget.value)
    if (isNaN(originalSelectedDate)) {
      return
    }

    const startDate = new Date(originalSelectedDate)
    startDate.setDate(startDate.getDate() - nbDaysAroundHolidayDays)

    const endDate = new Date(originalSelectedDate)
    endDate.setDate(endDate.getDate() + nbDaysAroundHolidayDays)

    const holidayDays = JSON.parse(this.holidayWarningTarget.dataset.holidayDays)

    let currentDate = new Date(startDate)
    while (currentDate <= endDate) {
      let day = currentDate.getDate().toString().padStart(2, '0')
      let month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
      let year = currentDate.getFullYear().toString().slice(-2)
      const formattedCurrentDate = `${day}-${month}-${year}`
      if (holidayDays.includes(formattedCurrentDate)) {
        this.holidayWarningTarget.innerHTML = i18n.t('your_translation_key', { date: formattedCurrentDate })
      }
      currentDate.setDate(currentDate.getDate() + 1)
    }
    this.updateTitleOfOrder()
  }

  updateTitleOfOrder() {
    const restaurantName = this.restaurantTarget.selectedOptions[0].text
    const titleInput = this.orderTitleTarget
    titleInput.value = `Order from ${restaurantName}`

    const deliveryDate = new Date(this.dateTarget.value)
    if (isNaN(deliveryDate)) {
      return
    } else {
      const deliveryDay = String(deliveryDate.getDate()).padStart(2, '0')
      const deliveryMonth = String(deliveryDate.getMonth() + 1).toString().padStart(2, '0')
      const deliveryYear = deliveryDate.getFullYear()
      const formattedDeliveryDate = `${deliveryDay}-${deliveryMonth}-${deliveryYear}`
      titleInput.value = `Order from ${restaurantName} - Delivery on : ${formattedDeliveryDate}`
    }
  }
}
