import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="recipes-checkbox"
export default class extends Controller {
    static targets = ['checkbox']

    connect() {
        console.log('RecipesCheckboxController connected.')
    }

    exportToPDF() {
        // Collecter les IDs sélectionnés dans un objet
        console.log(this.checkboxTargets)
        const selectedIds = this.checkboxTargets
            .filter((cb) => cb.checked)
            .map((cb) => cb.value)
        console.log(selectedIds)
        // Préparer les données à envoyer
        const data = { recipe_ids: selectedIds }

        let url = '/recipes/export_pdf'
        if (document.body.dataset.locale) {
            url = `/${document.body.dataset.locale}${url}`
        }
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector(
                    "meta[name='csrf-token']"
                ).content,
            },
            body: JSON.stringify(data),
        })
            .then((response) => {
                if (response.ok) {
                    return response.blob()
                }
                throw new Error('Network response was not ok.')
            })
            .then((blob) => {
                // Créer un URL pour le blob
                const url = window.URL.createObjectURL(blob)
                // Créer un élément 'a' et simuler un clic pour télécharger le fichier
                const a = document.createElement('a')
                a.style.display = 'none'
                a.href = url
                a.download = 'recettes.pdf'
                document.body.appendChild(a)
                a.click()
                // Nettoyer l'URL et l'élément 'a'
                window.URL.revokeObjectURL(url)
                document.body.removeChild(a)
            })
            .catch((error) => console.error('Fetch error:', error))
    }
}
