import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="ean-code-cash-register"
export default class extends Controller {
    static targets = [
        'buttonPickEan',
        'buttonGenerate',
        'generatedEanCode',
        'eanValidationIcon',
        'submitButton',
        'eanField',
        'buttonClear',
    ]

    generate(event) {
        event.preventDefault()
        const itemId = this.buttonGenerateTarget.value
        const generatedEanField = this.generatedEanCodeTarget
        // const eanField = this.eanFieldTarget
        const buttonGenerate = this.buttonGenerateTarget
        const buttonClear = this.buttonClearTarget
        const buttonPickUp = this.buttonPickEanTarget

        if (
            generatedEanField.value &&
            !confirm(
                'Êtes-vous sûr de vouloir remplacer le code EAN existant ?'
            )
        )
            return
        let url = `/cash_register_items/${itemId}/generate_ean`
        if (document.body.dataset.locale) {
            url = `/${document.body.dataset.locale}${url}`
        }
        fetch(url, {
            method: 'POST',
            headers: {
                'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
                    .content,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                generatedEanField.value = data.ean_code
                // eanField.disabled = true
                buttonGenerate.disabled = true
                buttonClear.disabled = false
                generatedEanField.disabled = true
                buttonPickUp.disabled = true
            })
    }

    clear(event) {
        event.preventDefault()
        const itemId = this.buttonGenerateTarget.value
        const generatedEanField = this.generatedEanCodeTarget
        // const eanField = this.eanFieldTarget
        const buttonGenerate = this.buttonGenerateTarget
        const buttonClear = this.buttonClearTarget
        const buttonPickUp = this.buttonPickEanTarget

        if (
            !confirm(
                'Êtes-vous sûr de vouloir supprimer le code EAN existant ?'
            )
        )
            return
        let url = `/cash_register_items/${itemId}/clear_ean`
        if (document.body.dataset.locale) {
            url = `/${document.body.dataset.locale}${url}`
        }
        fetch(url, {
            method: 'PATCH',
            headers: {
                'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
                    .content,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                generatedEanField.value = ''
                // eanField.disabled = false
                buttonGenerate.disabled = false
                buttonClear.disabled = true
                // generatedEanField.disabled = false
                buttonPickUp.disabled = false
            })
    }
    pickEanFromIngredient(event) {
        event.preventDefault()
        const buttonPickUp = this.buttonPickEanTarget
        const buttonGenerate = this.buttonGenerateTarget
        const buttonClear = this.buttonClearTarget
        console.log(
            'buttonPickUp.dataset.eanValue',
            buttonPickUp.dataset.eanValue
        )
        const generatedEanField = this.generatedEanCodeTarget
        generatedEanField.value = buttonPickUp.dataset.eanValue
        buttonPickUp.disabled = true
        // generatedEanField.disabled = true
        buttonGenerate.disabled = true
        buttonClear.disabled = false
    }
}
