// Entry point for the build script in your package.json
import '@hotwired/turbo-rails'
import './controllers'
import './components'
import 'chartkick/chart.js'

import 'bootstrap'
import './components/tooltip'

require('jquery')
require('@nathanvda/cocoon')
//= require cocoon
//= require highcharts
//= require chartkick


//= require pagy


import './components/tooltip'
import "./channels/notification_channel"

