import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="flatpickr-standard"
export default class extends Controller {
    connect() {
        // Envoyer en mindate dans le erb un data-min-date-value = date désirée
        // mettre le data controleur sur l'élément input
        const minDate = this.element.dataset.minDateValue
        console.log(minDate)
        flatpickr(this.element, {
            locale: { firstDayOfWeek: 1 },
            minDate: minDate,
            dateFormat: 'd/m/Y',
        })
    }
}
