import { Controller } from "@hotwired/stimulus"
import { I18n } from 'i18n-js'
import translations from '../translations.json'

const i18n = new I18n(translations)

const userLocale = document
    .querySelector("meta[name='user-locale']")
    .getAttribute('content')
i18n.locale = userLocale

// Connects to data-controller="loading"
export default class extends Controller {
  connect() {
    this.element.addEventListener("submit", this.showLoadingModal.bind(this))
  }

  disconnect() {
    this.element.removeEventListener("submit", this.showLoadingModal.bind(this))
  }

  showLoadingModal(event) {
    const customMessage = this.element.dataset.loadingMessage || `${i18n.t('i18njs.Please wait while we process your request')}...`

    document.querySelector('#loadingModal .modal-body p').textContent = customMessage

    const loadingModal = new bootstrap.Modal(document.getElementById('loadingModal'))
    loadingModal.show()
  }
}
