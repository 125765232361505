import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="holiday-agenda"
export default class extends Controller {
    connect() {
        this.generateEventColors()
    }

    generateEventColors() {
        document.querySelectorAll('.event').forEach((eventElement) => {
            if (!eventElement.classList.contains('single-day')) {
                const eventId = eventElement.className.match(/event-(\d+)/)[1]
                const color = this.generateColor(eventId)
                eventElement.style.backgroundColor = color
            }
        })
    }

    generateColor(id) {
        let hash = 0
        for (let i = 0; i < id.length; i++) {
            hash = id.charCodeAt(i) + ((hash << 5) - hash)
        }
        let color = '#'
        for (let i = 0; i < 3; i++) {
            const value = (hash >> (i * 8)) & 0xff
            color += ('00' + value.toString(16)).substr(-2)
        }
        return color
    }
}
