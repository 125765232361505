import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="menu"
export default class extends Controller {
    static targets = ['toggle', 'nav', 'body', 'header', 'navLink']
    isLocked = false // État pour verrouiller/déverrouiller le menu
    isOpen = false // État pour suivre si le menu est ouvert ou fermé

    connect() {
        console.log('menu loaded')

        // Charger l'état précédent du menu à partir de localStorage
        this.isLocked = localStorage.getItem('menuLocked') === 'true'
        this.isOpen = localStorage.getItem('menuOpen') === 'true'
        this.applyMenuState()
        this.initializeIndexValue()

        this.navLinkTargets.forEach((link) => {
            link.addEventListener('click', this.handleLinkClick.bind(this))
        })

        // garde le menu ouvert avec menu item sélectionné au changement de page
        const activeAccordion = localStorage.getItem('activeAccordion')

        if (activeAccordion) {
            const accordionElement = document.querySelector(activeAccordion)
            if (accordionElement) {
                accordionElement.classList.add('show')
                accordionElement.style.maxHeight =
                    accordionElement.scrollHeight + 'px'
                // Ajouter la classe 'down' à l'élément .nav_link
                const navLink = document.querySelector(
                    `[data-bs-target="${activeAccordion}"]`
                )
                if (navLink) {
                    navLink.classList.add('down')
                }
            }
        }
    }

    handleLinkClick(event) {
        this.navLinkTargets.forEach((link) => {
            link.classList.remove('active')
        })
        event.currentTarget.classList.add('active')
        // garde le menu ouvert avec menu item sélectionné au changement de page
        localStorage.setItem(
            'activeAccordion',
            event.currentTarget.getAttribute('data-bs-target')
        )
    }

    toggleMenu() {
        this.isOpen = !this.isOpen
        this.applyMenuState()
    }

    lockMenu() {
        this.isLocked = !this.isLocked
        if (!this.isLocked) {
            this.isOpen = false
        } else {
            this.isOpen = true
        }
        this.applyMenuState()

        // Sauvegarder l'état actuel du menu dans localStorage
        localStorage.setItem('menuLocked', this.isLocked)
        localStorage.setItem('menuOpen', this.isOpen)
    }

    applyMenuState() {
        const action = this.isOpen ? 'add' : 'remove'
        this.navTarget.classList[action]('show')
        this.bodyTarget.classList[action]('body-pd')
        this.headerTarget.classList[action]('body-pd')
    }

    openMenu() {
        if (this.isLocked) return
        this.isOpen = true
        this.applyMenuState()
    }

    closeMenu() {
        if (this.isLocked) return
        this.isOpen = false
        this.applyMenuState()
    }

    toggleAccordion(event) {
        console.log('toggleAccordion called')
        const currentAccordionContent = document.querySelector(
            event.currentTarget.getAttribute('data-bs-target')
        )

        // Fermer tous les autres éléments d'accordéon
        document.querySelectorAll('.collapse.show').forEach((el) => {
            if (el !== currentAccordionContent) {
                el.classList.remove('show')
                el.style.maxHeight = null
                // Retirer la classe 'active' du nav_link correspondant
                const parentNavLink = document.querySelector(
                    `[data-bs-target="#${el.id}"]`
                )
                if (parentNavLink) {
                    parentNavLink.classList.remove('active')
                    // Ajouter la classe 'down' à l'icône de flèche
                    parentNavLink.classList.remove('down')
                }
            }
        })

        // Ouvrir ou fermer l'élément d'accordéon courant
        if (currentAccordionContent) {
            currentAccordionContent.classList.toggle('show')
            if (currentAccordionContent.style.maxHeight) {
                currentAccordionContent.style.maxHeight = null
                // Ajouter la classe 'down' à l'icône de flèche
                event.currentTarget.classList.remove('down')
            } else {
                currentAccordionContent.style.maxHeight =
                    currentAccordionContent.scrollHeight + 'px'
                // Retirer la classe 'down' de l'icône de flèche
                event.currentTarget.classList.add('down')
            }

            // Gérer la classe 'active'
            if (currentAccordionContent.style.maxHeight) {
                event.currentTarget.classList.add('active')
            } else {
                event.currentTarget.classList.remove('active')
            }
        }
    }

    initializeIndexValue() {
        const specificPageContainer = document.getElementById(
            'user-permission-container'
        )

        if (specificPageContainer) {
            const header = document.querySelector('.header')
            const navBar = document.querySelector('#nav-bar')
            if (header) {
                header.classList.add('header-index')
            }

            if (navBar) {
                navBar.classList.add('nav-index')
            }
        }
    }
}
