// app/javascript/channels/notification_channel.js
import consumer from "./consumer"

console.log("Subscribing to NotificationChannel...");

consumer.subscriptions.create("NotificationChannel", {
  connected() {
    console.log("Connected to NotificationChannel");
  },
  disconnected() {
    console.log("Disconnected from NotificationChannel");
  },
  received(data) {
    console.log("Received data:", data);
    alert(data.message); // Affiche une alerte avec le message reçu
  }
});
