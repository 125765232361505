import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="chart-end-year-rebates"
export default class extends Controller {
    connect() {
        console.log('highcharts turnover')
        this.loadChart()
    }

    loadChart() {
        const chartData = this.element.querySelector('#chart-data')
        const categoriesData = JSON.parse(
            chartData.getAttribute('data-categories')
        )
        const seriesData = JSON.parse(chartData.getAttribute('data-series'))

        Highcharts.chart('chart-data', {
            chart: {
                type: 'column', // Ceci spécifie un graphique à bâtonnets
                height: 300,
            },
            title: {
                text: null,
                // useHTML: false,
                // text: '<i class="fa-solid fa-chart-bar fa-2xl text-primary"></i> ' + "Turnover by restaurant",
            },
            xAxis: {
                categories: categoriesData, // Les noms des restaurants
                crosshair: true,
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Rebates (€)',
                },
            },
            tooltip: {
                shared: true,
                useHTML: true,
                formatter: function () {
                    let tooltipHtml =
                        '<span style="font-size:10px">' +
                        this.key +
                        '</span><table>'
                    this.points.forEach((point) => {
                        tooltipHtml +=
                            '<tr><td style="color:' +
                            point.series.color +
                            ';padding:0">' +
                            'Turnover' +
                            ': </td>' +
                            '<td style="padding:0"><b>' +
                            point.y.toLocaleString('fr-FR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }) +
                            ' €</b></td></tr>'
                    })
                    tooltipHtml += '</table>'
                    return tooltipHtml
                },
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                },
            },
            series: [
                {
                    name: 'Rebate by brand',
                    data: seriesData, // Les données de chiffre d'affaires
                },
            ],
            credits: {
                enabled: false,
            },
        })
    }
}
