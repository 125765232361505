import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="supplier-order-summary"
export default class extends Controller {
    submitMonths() {
        const form = document.getElementById('date-form')
        const formData = new FormData(form)
        const monthYearData = Object.fromEntries(formData)
        let url = '/supplier_orders/monthly_report_confirmed_supplier_orders'
        if (document.body.dataset.locale) {
            url = `/${document.body.dataset.locale}${url}`
        }
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector(
                    "meta[name='csrf-token']"
                ).content,
            },
            body: JSON.stringify(monthYearData),
        })
            .then((response) => {
                if (response.ok) {
                    return response.blob()
                }
                throw new Error('Network response was not ok.')
            })
            .then((blob) => {
                const url = window.URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.style.display = 'none'
                a.href = url
                a.download = `monthly_report_${monthYearData.month}_${monthYearData.year}.pdf`
                document.body.appendChild(a)
                a.click()
                window.URL.revokeObjectURL(url)
            })
            .catch((error) => {
                console.error('Fetch error:', error)
            })
    }
}
