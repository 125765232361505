import { Controller } from '@hotwired/stimulus'
import { I18n } from 'i18n-js'
import translations from '../translations.json'

const i18n = new I18n(translations)
const userLocale = document
    .querySelector("meta[name='user-locale']")
    .getAttribute('content')
i18n.locale = userLocale

// Connects to data-controller="confirm-order"
export default class extends Controller {
    differenceErbValue = null
    static targets = [
        'orderComment',
        'modal',
        'badState',
        'badStateExplanation',
        'badTemperatureExplanation',
        'temperature',
        'totalDeposit',
        'quantityField',
        'checkbox',
        'productQty',
        'qtyByPack',
        'totalQty',
        'receivedQtyPack',
    ]

    connect() {
        const alertTotal = document.getElementById('alert-total')
        this.differenceErbValue = parseFloat(alertTotal.dataset.value)
        this.updateTotal()
        this.showBadTemperatureExplanation()
        this.showBadStateExplanation()
        this.receivedQtyPackTargets.forEach((target) => {
            const id = target.dataset.id
            const packsReceived = target.value
            this.calculateQuantity(id, packsReceived)
        })
    }

    handleChange(event) {
        const target = event.currentTarget
        const id = target.dataset.id
        const packsReceived = target.value
        this.calculateQuantity(id, packsReceived)
    }

    calculateQuantity(id, packsReceived) {
        if (packsReceived <= 0) {
            packsReceived = 0
        }
        const unitPriceValue = this.element.querySelector(
            `td[data-unit-price="${'unitPrice' + id}"]`
        ).dataset.value
        const nbItemsInPack = this.element.querySelector(
            `td[data-pack-size="${'packSize' + id}"]`
        ).dataset.value
        const totalOrderedPrice = this.element.querySelector(
            `td[data-ordered-price="${'totalOrderedPrice' + id}"]`
        ).dataset.value
        const totalOrderPrice = document.querySelector(
            `td span[data-total-order-price="totalOrderPrice"]`
        )
        const totalPrice =
            (nbItemsInPack * packsReceived * unitPriceValue) / 100
        const totalItems = packsReceived * nbItemsInPack
        const difference = totalOrderedPrice / 100 - totalPrice

        const totalRowItems = this.element.querySelector(
            `td span[data-total-items="${'totalItems' + id}"]`
        )
        totalRowItems.innerHTML = totalItems
        const totalRowPrice = this.element.querySelector(
            `td span[data-total-price="${'totalPrice' + id}"]`
        )
        totalRowPrice.innerHTML =
            '€' +
            totalPrice.toLocaleString('fr-FR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })
        const differenceRow = this.element.querySelector(
            `td span[data-difference="${'difference' + id}"]`
        )
        differenceRow.innerHTML =
            '€' +
            difference.toLocaleString('fr-FR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })
        if (difference !== 0) {
            differenceRow.classList.add('text-danger')
        } else {
            differenceRow.classList.remove('text-danger')
        }
        totalOrderPrice.innerHTML =
            '€' +
            this.calculateTotalOrderPrice().toLocaleString('fr-FR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })
        this.changeStyleIfDifference()
    }

    changeStyleIfDifference() {
        const alertTotal = document.getElementById('alert-total')
        const totalDiffenceCells = document.querySelectorAll(
            `td span[data-difference]`
        )
        let totalDifference = 0
        totalDiffenceCells.forEach((cell) => {
            totalDifference += parseFloat(
                cell.innerHTML.replace('€', '').replace(',', '.')
            )
        })
        const temperatureIsNotEmpty = this.temperatureTarget.value !== ''
        const supplierIsNonFood =
            this.temperatureTarget.dataset.isNonFoodSupplier === 'true'
        let orderCommentEmpty = this.orderCommentTarget.value.trim() === ''
        const iconHTML =
            totalDifference === 0
                ? '<i class="fa-solid fa-check"></i>&nbsp;'
                : '<i class="fa-solid fa-triangle-exclamation"></i>&nbsp;'
        const text =
            totalDifference === 0
                ? i18n.t(
                      'i18njs.The amount validated is the same as the amount of the order'
                  )
                : i18n.t(
                      'i18njs.The total price validated is different from the total price of the order, please explain in comment zone the reason of differences if needed'
                  )
        const alertType = totalDifference === 0 ? 'success' : 'danger'

        alertTotal.innerHTML = iconHTML + text
        alertTotal.classList.remove(
            'alert-' + (alertType === 'success' ? 'danger' : 'success')
        )
        alertTotal.classList.add('alert-' + alertType)
        console.log('this.areAllQuantitiesZero()', this.areAllQuantitiesZero())
        const enableModal =
            (temperatureIsNotEmpty && !supplierIsNonFood) ||
            supplierIsNonFood ||
            this.areAllQuantitiesZero()
        console.log('enableModal', enableModal)
        // if (totalDifference !== 0 && orderCommentEmpty) {
        if (!enableModal) {
            this.modalTarget.setAttribute('disabled', 'true')
        } else if (enableModal) {
            this.modalTarget.removeAttribute('disabled')
        }
    }

    areAllQuantitiesZero() {
        this.receivedQtyPackTargets.every((field) =>
            console.log('field', field.value)
        )
        return this.receivedQtyPackTargets.every(
            (field) => parseInt(field.value) === 0
        )
    }

    calculateTotalOrderPrice() {
        const totalPriceCells = document.querySelectorAll(
            `td span[data-total-price]`
        )
        let totalOrderPrice = 0
        totalPriceCells.forEach((cell) => {
            totalOrderPrice += parseFloat(
                cell.innerHTML.replace('€', '').replace(',', '.')
            )
        })
        return totalOrderPrice
    }

    showBadStateExplanation() {
        const badStateExplanation = this.badStateExplanationTarget
        console.log(this.badStateTarget.checked)
        const method = this.badStateTarget.checked ? 'add' : 'remove'
        console.log(method)
        badStateExplanation.classList[method]('d-none')
    }

    showBadTemperatureExplanation() {
      if (this.hasBadTemperatureExplanationTarget) {  
          const badTemperatureExplanation = this.badTemperatureExplanationTarget
          const temperature = parseInt(this.temperatureTarget.value, 10)
          const maxTemperature = parseInt(this.temperatureTarget.dataset.maxTemperatureParameter, 10)

          const method = temperature >= maxTemperature ? 'remove' : 'add'
          badTemperatureExplanation.classList[method]('d-none')
      }
      this.changeStyleIfDifference()
  }

    fillQuantities() {
        const receivedQtyPackInputs = document.querySelectorAll(
            'input[name*="received_qty_pack"]'
        )
        receivedQtyPackInputs.forEach((input, index) => {
            const itemRow = input.closest('tr')
            const orderedQuantityCell = itemRow.querySelector('td.bg-warning')
            const orderedQuantity = parseInt(
                orderedQuantityCell.textContent,
                10
            )
            input.value = orderedQuantity
            // Déclenchez manuellement l'événement 'change' pour mettre à jour les autres champs
            const event = new Event('change', {
                bubbles: true,
                cancelable: true,
            })
            input.dispatchEvent(event)
        })
    }

    updateTotal() {
        let totalDeposit = 0

        this.quantityFieldTargets.forEach((field, index) => {
            const quantity = parseInt(field.value || '0')
            const valueCents = parseInt(field.dataset.confirmOrderValue)
            const checkboxId = `checkbox_${field.dataset.confirmOrderValue}`
            const checkboxElement = this.checkboxTargets.find(
                (el) => el.id === checkboxId
            )

            if (checkboxElement.checked) {
                // Si la checkbox est cochée (in), on ajoute la valeur au total
                totalDeposit -= quantity * valueCents
            } else {
                // Si la checkbox n'est pas cochée (out), on soustrait la valeur du total
                totalDeposit += quantity * valueCents
            }
        })

        this.totalDepositTarget.innerText = new Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: 'EUR',
        }).format(totalDeposit / 100)
    }
    addRow() {
        const currentRow = document.querySelector('.current-row')
        const newRow = `
      <td scope="row" colspan="4" class="w-100">
        <input name="supplier_order[ask_referencing][][new_product]" placeholder="Name of product not refered" class="w-100 form-control form-control-sm">
      </td>
      <td scope="row" colspan="2" class="text-end align-middle">
        <input name="supplier_order[ask_referencing][][new_product_ref]" placeholder="Supplier Reference" class="w-100 form-control form-control-sm">
      </td>
      <td scope="row" colspan="2" class="text-end align-middle">
        <input type="number" min="0" name="supplier_order[ask_referencing][][new_product_qty]"  class="w-100 form-control form-control-sm" placeholder="Quantity of pack" data-confirm-order-target="productQty" data-action="input->confirm-order#calculateTotal">
      </td>
      <td scope="row" colspan="1" class="text-end align-middle">
        <input type="number" min="0" name="supplier_order[ask_referencing][][qty_by_pack]"  class="w-100 form-control form-control-sm" placeholder="Items by pack" data-confirm-order-target="qtyByPack" data-action="input->confirm-order#calculateTotal">
      </td>
      <td scope="row" colspan="2" class="text-end align-middle">
        <input type="number" name="supplier_order[ask_referencing][][total_qty]"  class="w-100 form-control form-control-sm no-spin" placeholder="Total quantity" data-confirm-order-target="totalQty">
      </td>
      <td scope="row" colspan="1" class="align-middle">
        <button type="button" class="btn btn-danger" data-action="click->confirm-order#removeRow"><i class="fa-regular fa-trash-can"></i></button>
      </td>
    `
        currentRow.insertAdjacentHTML('afterend', newRow)
    }

    removeRow(event) {
        console.log(event.target.closest('tr'))
        console.log(event.currentTarget)
        console.log(event.currentTarget)
        console.log(event.currentTarget.closest('tr'))
        event.target.closest('tr').remove()
    }
    calculateTotal() {
        this.totalQtyTargets.forEach((totalQtyEl, index) => {
            const productQty =
                parseFloat(this.productQtyTargets[index].value) || 0
            const qtyByPack =
                parseFloat(this.qtyByPackTargets[index].value) || 0

            // Mise à jour du total
            totalQtyEl.value = productQty * qtyByPack
        })
    }
}
