import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="quill"
// pour intilaliser, faire comme ci dessous. Les champs peut être répétés, quill va détecter
// grace aux ID les champs à initialiser propres à chaque area. Exemple :
{
    /* <div class="mb-3" data-controller="quill" data-quill-target-value="recipe_text">
<div id="editor_recipe_text" class="form-control" style="height:auto;"></div>
<%= f.input :recipe_text, as: :hidden, input_html: { data: { quill_target: 'field'}, id: 'recipe_text' } %>
</div> */
}

export default class extends Controller {
    static targets = ['field']

    connect() {
        this.initQuill()
        this.loadInitialContent()
        // Following lines avoid the warning "you are leaving the page without saving your changes"
        this.quillHasFocus = false
        this.quill.on('editor-change', () => {
            this.quillHasFocus = true
        })
    }

    initQuill() {
        const targetValue = this.data.get('target-value')
        this.editorElement = this.element.querySelector(
            `#editor_${targetValue}`
        )

        this.quill = new Quill(this.editorElement, {
            theme: 'snow',
            modules: {
                toolbar: [
                    ['bold', 'underline'], // toggled buttons
                    // ['blockquote', 'code-block'],

                    // [{ 'header': 1 }, { 'header': 2 }],     // custom button values
                    // [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                    // [{ 'script': 'sub'}, { 'script': 'super' }], // superscript/subscript
                    // [{ 'indent': '-1'}, { 'indent': '+1' }], // outdent/indent

                    // [{ 'color': [] }, { 'background': [] }], // dropdown with defaults from theme

                    // [{ 'align': [] }],

                    ['clean'], // remove formatting button
                ],
            },
        })

        this.quill.on('text-change', this.updateHiddenField.bind(this))
    }

    loadInitialContent() {
        const targetValue = this.data.get('target-value')
        const hiddenField = this.fieldTargets.find(
            (field) => field.id === targetValue
        )

        if (hiddenField && hiddenField.value) {
            this.quill.clipboard.dangerouslyPasteHTML(hiddenField.value)
        }
    }

    updateHiddenField() {
        const targetValue = this.data.get('target-value')
        const hiddenField = this.fieldTargets.find(
            (field) => field.id === targetValue
        )

        if (hiddenField) {
            const content = this.quill.root.innerHTML
            hiddenField.value = content
        }
    }
}
