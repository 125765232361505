import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="cash-register-item-print-labels"
export default class extends Controller {
    openQuantityModal() {
        const checkboxes = document.querySelectorAll('.ingredient-checkbox')
        const form = document.getElementById('quantity-label-form')
        // Initialisation du tableau HTML
        let tableHTML = `
      <div class="table-responsive mt-3" style="max-height: 500px; overflow-y: auto;">
        <table class="table table-striped table-hover table-sm">
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Nom</th>
              <th scope="col" class="text-center">Quantité</th>
            </tr>
          </thead>
          <tbody>
    `

        // Remplissage du tableau avec les ingrédients sélectionnés
        checkboxes.forEach((checkbox) => {
            if (checkbox.checked) {
                const id = checkbox.dataset.ingredientId
                const name = checkbox.dataset.ingredientName
                const ean = checkbox.dataset.ingredientEan
                let additionalColumn = ''

                tableHTML += `
          <tr>
            <td>${id}</td>
            <td>${name}</td>
            <td><input type="number" id="${id}" name="${id}" min="0" value="1" class="form-control text-center"></td>
            ${additionalColumn}
          </tr>
        `
            }
        })
        // Fermeture du tableau HTML
        tableHTML += `
          </tbody>
        </table>
      </div>
    `
        // Ajout du tableau HTML au formulaire
        form.innerHTML = tableHTML
        // Ouverture du modal
        const modal = new bootstrap.Modal(
            document.getElementById('quantityLabelModal')
        )
        modal.show()
    }

    submitQuantities() {
        let selectedPriceRange =
            document.getElementById('price-range-select').value
        if (!selectedPriceRange) {
            selectedPriceRange = '1'
        }
        const formData = new FormData(
            document.getElementById('quantity-label-form')
        )
        const quantities = Object.fromEntries(formData)
        let url = '/cash_register_items/print_multiple_labels'
        if (document.body.dataset.locale) {
            url = `/${document.body.dataset.locale}${url}`
        }
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
                    .content,
            },
            body: JSON.stringify({
                ingredient_quantities: quantities,
                selected_price_range: selectedPriceRange,
            }),
        })
            .then((response) => {
                if (response.ok) {
                    return response.blob()
                }
                throw new Error('Network response was not ok.')
            })
            .then((blob) => {
                const url = window.URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.style.display = 'none'
                a.href = url
                a.download = 'multiple_products_labels.pdf'
                document.body.appendChild(a)
                a.click()
                window.URL.revokeObjectURL(url)
            })
            .catch((error) => {
                console.error('Fetch error:', error)
            })
    }
}
