document.addEventListener('DOMContentLoaded', function () {
    const flashMessage = document.querySelector('.flash-message')

    if (flashMessage) {
        const message = flashMessage.textContent || flashMessage.innerText
        const type = flashMessage.dataset.type || 'info'

        var toastElement = `
      <div class="toast align-items-center text-white bg-${type} border-0" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="d-flex">
          <div class="toast-body">
            ${message}
          </div>
          <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
      </div>
    `

        document
            .getElementById('toast-container')
            .insertAdjacentHTML('beforeend', toastElement)
        var toast = new bootstrap.Toast(document.querySelector('.toast'))
        toast.show()
    }
})
