import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="birthday"
export default class extends Controller {
  connect() {
    const today = new Date();
    const birthdateElements = this.element.querySelectorAll("li");
    let closestElement = null;
    let closestDaysDiff = Number.MAX_VALUE;

    birthdateElements.forEach(element => {
      const dateString = element.textContent.match(/\d{2}\/\d{2}/)[0];
      const [day, month] = dateString.split('/').map(Number);
      const birthday = new Date(today.getFullYear(), month - 1, day);
      let diffDays = Math.abs((birthday - today) / (1000 * 60 * 60 * 24));

      if (diffDays < closestDaysDiff) {
        closestDaysDiff = diffDays;
        closestElement = element;
      }
    });

    if (closestElement) {
      closestElement.scrollIntoView({ block: "center" });
    }
  }
}
