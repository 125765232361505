import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form', 'generalErrorMessage']

  connect() {}

  submitForm(event) {
    event.preventDefault()
    const restaurantCheckboxes = document.querySelectorAll(
      '.form-check-input[data-restaurant-id]'
    )

    let shouldSubmitForm = true
    let hasCheckedDeliveryDay = []

    restaurantCheckboxes.forEach((restaurantCheckbox) => {
      const restaurantId =
        restaurantCheckbox.getAttribute('data-restaurant-id')
      const nestedFields = document.querySelectorAll(
        `.nested-fields[data-restaurant-id="${restaurantId}"]`
      )

      if (restaurantCheckbox.checked) {
        const validDaysCheckboxes = this.verifyDaysCheckboxes(
          nestedFields,
          hasCheckedDeliveryDay
        )
        shouldSubmitForm = shouldSubmitForm && validDaysCheckboxes
      }
    })

    if (shouldSubmitForm) {
      this.clearErrorMessages()
      this.formTarget.submit()
    } else {
      // Affiche le message d'erreur général si une validation échoue
      this.generalErrorMessageTarget.classList.remove('d-none')
    }
  }

  verifyDaysCheckboxes(nestedFields, hasCheckedDeliveryDay) {
    let isValid = true

    nestedFields.forEach((field) => {
      const errorMessage = field.querySelector('.error-message')
      const deliveryDayCheckboxes = field.querySelectorAll(
        'input[type="checkbox"][data-action="change->supplier-delivery-options#select"]'
      )

      const anyDeliveryDayChecked = Array.from(
        deliveryDayCheckboxes
      ).some((ck) => ck.checked)
      hasCheckedDeliveryDay.push(anyDeliveryDayChecked)

      if (
        hasCheckedDeliveryDay.length > 0 &&
        hasCheckedDeliveryDay.every((ele) => ele === true)
      ) {
        errorMessage.style.display = 'none'
        isValid = true
      } else if (anyDeliveryDayChecked) {
        errorMessage.style.display = 'none'
        isValid = false
      } else {
        errorMessage.style.display = 'block'
        isValid = false
      }
    })

    return isValid
  }

  clearErrorMessages() {
    const errorMessages = document.querySelectorAll(
      '.nested-fields .error-message'
    )
    errorMessages.forEach((errorMessage) => {
      errorMessage.style.display = 'none'
    })
    // Cache le message d'erreur général
    this.generalErrorMessageTarget.classList.add('d-none')
  }
}
