import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checkbox-select-all"
export default class extends Controller {
  static targets = ["checkbox", "checkboxAll"];

  connect() {
    console.log("CheckboxSelectAllController connected");
  }

  toggleAll() {
    const isChecked = this.checkboxAllTarget.checked;
    console.log("toggleAll status:", isChecked);
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = isChecked;
    });
  }
}
