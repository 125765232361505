import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['date', 'allDay', 'startTime', 'endTime']

    connect() {
        console.log('hello flatpickr holiday')
        flatpickr(this.dateTarget, {
            locale: { firstDayOfWeek: 1 },
            mode: 'range',
            inline: true,
            appendTo: document.getElementById('flatpickr-date-container'),
            dateFormat: 'Y-m-d',
            onChange: (selectedDates, dateStr, instance) => {
                if (selectedDates.length === 2) {
                    const startDate = selectedDates[0]
                    const endDate = selectedDates[1]
                    const formattedStartDate = this.formatDate(startDate)
                    const formattedEndDate = this.formatDate(endDate)
                    this.dateTarget.value = `${formattedStartDate} to ${formattedEndDate}`
                    if (startDate.toDateString() === endDate.toDateString()) {
                        this.allDayTarget.disabled = false
                    } else {
                        this.allDayTarget.checked = true
                        this.allDayTarget.disabled = true
                        const timeFields =
                            document.querySelector('.time-fields')
                        timeFields.classList.add('d-none')
                        this.resetEndTime()
                        this.resetStartTime()
                    }
                }
            },
            onReady: (selectedDates, dateStr, instance) => {
                if (dateStr) {
                    console.log('dateStr', dateStr)
                    let [startDateStr, endDateStr] = dateStr.split(' to ')
                    // if it's the same day, need to define the end date as well
                    endDateStr = endDateStr || startDateStr
                    const startDate = new Date(startDateStr)
                    const endDate = new Date(endDateStr)
                    console.log('startDate', startDate)
                    console.log('endDate', endDate)
                    const formattedStartDate = this.formatDate(startDate)
                    console.log('formattedStartDate', formattedStartDate)
                    const formattedEndDate = this.formatDate(endDate)
                    console.log('formattedEndDate', formattedEndDate)
                    this.dateTarget.value = `${formattedStartDate} to ${formattedEndDate}`
                }
            },
        })
        console.log('this.allDayTarget', this.allDayTarget)
        console.log('this.allDayTarget', this.allDayTarget.checked)
        if (!this.allDayTarget.checked) {
            const timeFields = document.querySelector('.time-fields')
            timeFields.classList.remove('d-none')
            this.initializeTimeFields()
        }
        this.element.addEventListener(
            'submit',
            this.enableAllDayCheckbox.bind(this)
        )
    }

    formatDate(date) {
        const day = String(date.getDate()).padStart(2, '0')
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const year = date.getFullYear()
        return `${day}-${month}-${year}`
    }

    toggleTimeFields(event) {
        console.log('toggleTimeFields')
        const isChecked = event.target.checked
        console.log('ischecked', isChecked)
        const timeFields = document.querySelector('.time-fields')
        console.log('timeFields', timeFields)

        if (isChecked) {
            timeFields.classList.add('d-none')
            this.resetEndTime()
            this.resetStartTime()
        } else {
            timeFields.classList.remove('d-none')
            this.initializeTimeFields()
        }
        console.log('timefields', timeFields)
    }
    updateEndTime(startTime) {
        if (startTime) {
            this.endTimeTarget._flatpickr.set('minDate', startTime)
        } else {
            this.endTimeTarget._flatpickr.set('minDate', '00:00')
        }
    }

    resetEndTime() {
        this.endTimeTarget._flatpickr.clear()
    }

    resetStartTime() {
        this.startTimeTarget._flatpickr.clear()
    }

    initializeTimeFields() {
        flatpickr(this.startTimeTarget, {
            enableTime: true,
            noCalendar: true,
            dateFormat: 'H:i',
            time_24hr: true,
            onChange: (selectedDates, dateStr) => {
                this.updateEndTime(dateStr)
            },
        })

        flatpickr(this.endTimeTarget, {
            enableTime: true,
            noCalendar: true,
            dateFormat: 'H:i',
            time_24hr: true,
            minDate: this.startTimeTarget.value,
        })
    }

    // necessary to avoid to not send the value of all_day if input is disabled
    enableAllDayCheckbox() {
        if (this.allDayTarget.disabled) {
            this.allDayTarget.disabled = false
        }
    }
}
