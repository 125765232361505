import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="ean-code"
export default class extends Controller {
    static targets = [
        'buttonGenerate',
        'generatedEanCode',
        'eanValidationIcon',
        'submitButton',
        'eanField',
        'buttonClear',
    ]

    connect() {
        this.changeEan()
    }

    changeEan() {
        const buttonGenerate = this.buttonGenerateTarget
        const eanField = this.eanFieldTarget.value
        const validationIconContainer = this.eanValidationIconTarget
        const submitButton = this.submitButtonTarget
        const isValidEan = this.validateEan(eanField)

        validationIconContainer.innerHTML = ''

        if (eanField.length === 0 || eanField.trim() === '') {
            buttonGenerate.disabled = false
            submitButton.disabled = false
        } else {
            buttonGenerate.disabled = true
            if (isValidEan) {
                validationIconContainer.innerHTML =
                    '<span class="text-success fw-bold"> <i class="fa-solid fa-circle-check text-success"></i> Valid EAN code</span>'
                submitButton.disabled = false
            } else {
                validationIconContainer.innerHTML =
                    '<span class="text-danger fw-bold"> <i class="fa-solid fa-circle-xmark text-danger"></i> Invalid EAN code</span>'
                submitButton.disabled = true
            }
        }
    }

    validateEan(eanCode) {
      const digits = String(eanCode).split('').map(Number);

      if (digits.length !== 8 && digits.length !== 13) {
          return false;
      }

      const checkDigit = digits.pop();
      let sum = 0;

      for (let i = 0; i < digits.length; i++) {
          let weight;
          if (digits.length === 7) {  // EAN-8
              weight = i % 2 === 0 ? 3 : 1;
          } else {  // EAN-13
              weight = i % 2 === 0 ? 1 : 3;
          }
          sum += digits[i] * weight;
      }

      const calculatedCheckDigit = (10 - (sum % 10)) % 10;
      return calculatedCheckDigit === checkDigit;
    }

    generate(event) {
        event.preventDefault()
        const ingredientId = this.buttonGenerateTarget.value
        const generatedEanField = this.generatedEanCodeTarget
        const eanField = this.eanFieldTarget
        const buttonGenerate = this.buttonGenerateTarget
        const buttonClear = this.buttonClearTarget

        if (
            generatedEanField.value &&
            !confirm(
                'Êtes-vous sûr de vouloir remplacer le code EAN existant ?'
            )
        )
            return
        let url = `/ingredients/${ingredientId}/generate_ean`
        if (document.body.dataset.locale) {
            url = `/${document.body.dataset.locale}${url}`
        }
        fetch(url, {
            method: 'POST',
            headers: {
                'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
                    .content,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                generatedEanField.value = data.ean_code
                eanField.disabled = true
                buttonGenerate.disabled = true
                buttonClear.disabled = false
            })
    }

    clear(event) {
        event.preventDefault()
        const ingredientId = this.buttonGenerateTarget.value
        const generatedEanField = this.generatedEanCodeTarget
        const eanField = this.eanFieldTarget
        const buttonGenerate = this.buttonGenerateTarget
        const buttonClear = this.buttonClearTarget

        if (
            !confirm(
                'Êtes-vous sûr de vouloir supprimer le code EAN existant ?'
            )
        )
            return
        let url = `/ingredients/${ingredientId}/clear_ean`
        if (document.body.dataset.locale) {
            url = `/${document.body.dataset.locale}${url}`
        }
        fetch(url, {
            method: 'PATCH',
            headers: {
                'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
                    .content,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                generatedEanField.value = ''
                eanField.disabled = false
                buttonGenerate.disabled = false
                buttonClear.disabled = true
            })
    }
}
