// import { Controller } from "@hotwired/stimulus";
// import "bootstrap"

// export default class extends Controller {
//   static targets = [ 'tooltip' ]

//   tooltipTargetConnected(element) {
//     new bootstrap.Tooltip(element)
//   }
// }
// import { Controller } from "@hotwired/stimulus";
// import "bootstrap";

// export default class extends Controller {
//   static targets = ['tooltip'];

//   tooltipTargetConnected(element) {
//     console.log("Tooltip controller connected");
//     this.initializeTooltip(element);
//   }

//   initializeTooltip(element) {
//     // Initialisation du tooltip Bootstrap
//     this.tooltip = new bootstrap.Tooltip(element);
//   }

//   tooltipTargetDisconnected(element) {
//     if (this.tooltip && element) {
//       this.tooltip.dispose();
//       this.tooltip = null; // Réinitialise après disposition pour éviter les erreurs futures
//     }
//   }
// }

import { Controller } from "@hotwired/stimulus";
import "bootstrap";

export default class extends Controller {
  static targets = ["tooltip"];

  connect() {
    this.initializeTooltips();

    // Créer un observer de mutation pour gérer les suppressions d'éléments
    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        mutation.removedNodes.forEach((node) => {
          if (node.nodeType === Node.ELEMENT_NODE) {
            // Si l'élément supprimé avait des tooltips, les disposer
            if (node.hasAttribute("data-bs-toggle") && node.getAttribute("data-bs-toggle") === "tooltip") {
              this.disposeTooltip(node);
            }
            // Si des enfants de l'élément supprimé avaient des tooltips, les disposer
            node.querySelectorAll("[data-bs-toggle='tooltip']").forEach((tooltipElement) => {
              this.disposeTooltip(tooltipElement);
            });
          }
        });
      });
    });

    this.observer.observe(document.body, { childList: true, subtree: true });
  }

  initializeTooltips() {
    this.tooltipTargets.forEach((element) => {
      this.createTooltip(element);
    });
  }

  createTooltip(element) {
    if (!element.tooltipInstance) {
      element.tooltipInstance = new bootstrap.Tooltip(element);
    }
  }

  tooltipTargetConnected(element) {
    this.createTooltip(element);
  }

  tooltipTargetDisconnected(element) {
    this.disposeTooltip(element);
  }

  disposeTooltip(element) {
    // Vérifier que l'élément existe et a une instance de tooltip
    if (element && element.tooltipInstance && document.body.contains(element)) {
      try {
        element.tooltipInstance.dispose();
        delete element.tooltipInstance;
      } catch (e) {
        console.warn("Erreur lors de la suppression du tooltip:", e);
      }
    }
  }

  disconnect() {
    // Nettoyer tous les tooltips restants lorsque le contrôleur est déconnecté
    this.disposeAllTooltips();

    // Arrêter l'observateur de mutation
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  disposeAllTooltips() {
    this.tooltipTargets.forEach((element) => this.disposeTooltip(element));
  }
}

