import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="checkbox-ingredient-index"
export default class extends Controller {
    static targets = [
        'button',
        'createCashRegisterItemsButton',
        'checkbox',
        'disableButton',
        'enableButton',
        'activeCheckbox',
    ]

    connect() {
        console.log('Hello, Stimulus!')
        console.log(this.buttonTarget)
        this.toggleButton()
        this.toggleActionButtons()
    }

    toggleButton() {
        // console.log('toggleButton')
        // const checkboxes = document.querySelectorAll('.ingredient-checkbox')
        // let atLeastOneChecked = false

        // checkboxes.forEach((checkbox) => {
        //     if (checkbox.checked) {
        //         atLeastOneChecked = true
        //     }
        // })

        // this.buttonTarget.disabled = !atLeastOneChecked

        // const anyChecked = this.checkboxTargets.some((checkbox) => checkbox.checked);
        // this.disableButtonTarget.disabled = !anyChecked;
        console.log('toggleButton')
        const checkboxes = document.querySelectorAll('.ingredient-checkbox')
        let atLeastOneChecked = false
        checkboxes.forEach((checkbox) => {
            if (checkbox.checked) {
                atLeastOneChecked = true
            }
        })
        this.disableButtonTarget.disabled = !atLeastOneChecked
        this.enableButtonTarget.disabled = !atLeastOneChecked
    }

    disableSelectedIngredients(event) {
        event.preventDefault()
        if (
            confirm(
                'Êtes-vous sûr de vouloir désactiver les ingrédients sélectionnés ?'
            )
        ) {
            const selectedIds = Array.from(
                document.querySelectorAll('.ingredient-checkbox')
            )
                .filter((checkbox) => checkbox.checked)
                .map((checkbox) => checkbox.dataset.ingredientId)

            // Mettre les IDs sélectionnés dans le formulaire caché et soumettre
            document.getElementById('ingredient_ids').value =
                selectedIds.join(',')
            document.getElementById('disable-selected-form').submit()
        }
    }

    enableSelectedIngredients(event) {
        event.preventDefault()
        if (
            confirm(
                'Êtes-vous sûr de vouloir activer les ingrédients sélectionnés ?'
            )
        ) {
            const selectedIds = Array.from(
                document.querySelectorAll('.ingredient-checkbox')
            )
                .filter((checkbox) => checkbox.checked)
                .map((checkbox) => checkbox.dataset.ingredientId)

            // Mettre les IDs sélectionnés dans le formulaire caché et soumettre
            document.getElementById('enable_ingredient_ids').value =
                selectedIds.join(',')
            document.getElementById('enable-selected-form').submit()
        }
    }

    toggleActionButtons() {
        console.log('toggleActionButtons')
        const activeCheckbox = this.activeCheckboxTarget.checked
        console.log('activeCheckbox', activeCheckbox)
        if (activeCheckbox) {
            this.disableButtonTarget.classList.add('d-none')
            this.enableButtonTarget.classList.remove('d-none')
        } else {
            this.disableButtonTarget.classList.remove('d-none')
            this.enableButtonTarget.classList.add('d-none')
        }
    }

    openQuantityModal() {
        const checkboxes = document.querySelectorAll('.ingredient-checkbox')
        const form = document.getElementById('quantity-form')

        // Initialisation du tableau HTML
        let tableHTML = `
      <div class="table-responsive">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Nom</th>
              <th scope="col">Quantité</th>
            </tr>
          </thead>
          <tbody>
    `

        // Remplissage du tableau avec les ingrédients sélectionnés
        checkboxes.forEach((checkbox) => {
            if (checkbox.checked) {
                const id = checkbox.dataset.ingredientId
                const name = checkbox.dataset.ingredientName
                const ean = checkbox.dataset.ingredientEan
                const generatedEan = checkbox.dataset.ingredientGeneratedEan

                let additionalColumn = ''
                let disabledInput = ''

                if (ean === '' && generatedEan === '') {
                    additionalColumn = `<td><strong><u>No EAN on product item</u></strong></td>`
                    disabledInput = 'disabled'
                }
                tableHTML += `
          <tr>
            <td>${id}</td>
            <td>${name}</td>
            <td><input type="number" class="text-center" id="${id}" name="${id}" min="0" value="1" ${disabledInput}></td>
            ${additionalColumn}
          </tr>
        `
            }
        })
        // Fermeture du tableau HTML
        tableHTML += `
          </tbody>
        </table>
      </div>
    `
        // Ajout du tableau HTML au formulaire
        form.innerHTML = tableHTML
        // Ouverture du modal
        const modal = new bootstrap.Modal(
            document.getElementById('quantityModal')
        )
        modal.show()
    }

    submitQuantities() {
        const formData = new FormData(document.getElementById('quantity-form'))
        const quantities = Object.fromEntries(formData)
        let url = '/ingredients/print_multiple_ean'
        if (document.body.dataset.locale) {
            url = `/${document.body.dataset.locale}${url}`
        }
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
                    .content,
            },
            body: JSON.stringify({ ingredient_quantities: quantities }),
        })
            .then((response) => {
                if (response.ok) {
                    return response.blob()
                }
                throw new Error('Network response was not ok.')
            })
            .then((blob) => {
                const url = window.URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.style.display = 'none'
                a.href = url
                a.download = 'multiple_ingredients.pdf'
                document.body.appendChild(a)
                a.click()
                window.URL.revokeObjectURL(url)
            })
            .catch((error) => {
                console.error('Fetch error:', error)
            })
    }

    openSelectedIngredientsModal() {
        const checkboxes = document.querySelectorAll('.ingredient-checkbox')
        const form = document.getElementById('selectedIngredientsList')

        // Initialisation du tableau HTML
        let tableHTML = `
      <div class="table-responsive">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">ID</th>
              <th scope="col">Nom</th>
              <th scope="col">TVA</th>
              <th scope="col">Catégorie</th>
              <th scope="col">Sous-Catégorie</th>
            </tr>
          </thead>
          <tbody>
    `
        // Remplissage du tableau avec les ingrédients sélectionnés
        checkboxes.forEach((checkbox) => {
            // cette condition permet de vérifier si un PLU existe déjà avec ce prooduit unique, et ne l'affiche pas
            const isUnique = checkbox.dataset.isUnique === 'true'
            if (checkbox.checked && !isUnique) {
                const id = checkbox.dataset.ingredientId
                const name = checkbox.dataset.ingredientName
                const itemTypes = JSON.parse(checkbox.dataset.itemTypes)
                const primaryCategories = JSON.parse(
                    checkbox.dataset.primaryCategories
                )

                let vatOptions = ''
                for (const type of itemTypes) {
                    vatOptions += `<option value="${type}">${type}</option>`
                }

                let primaryCategoryOptions = ''
                for (const category of primaryCategories) {
                    primaryCategoryOptions += `<option value="${category.id}">${category.name}</option>`
                }

                tableHTML += `
          <tr class="modal-create-cash-register-item">
            <td><input type="checkbox" class="modal-checkbox" checked data-ingredient-id="${id}" data-action="change->checkbox-ingredient-index#changeSelection" ></td>
            <td>${id}</td>
            <td>${name}</td>
            <td>
              <select class="form-select vat-select" data-action="change->checkbox-ingredient-index#changeSelection">
                <option value="">-- Select a VAT category --</option>
                ${vatOptions}
              </select>
            </td>
            <td>
              <select class="form-select primary-category-select" data-action="change->checkbox-ingredient-index#changeSelection change->checkbox-ingredient-index#updateSecondaryCategories" >
                <option value="">-- Select a category --</option>
                ${primaryCategoryOptions}
              </select>
            </td>
            <td>
              <select class="form-select secondary-category-select" disabled>
                <option value="">-- Select a sub category --</option>
              </select>
            </td>
            <td>
              <input type="text" class="form-control editable-name" value="${name}" data-action="input->checkbox-ingredient-index#changeSelection" />
            </td>
          </tr>
        `
            }
        })
        // Fermeture du tableau HTML
        tableHTML += `
          </tbody>
        </table>
      </div>
    `
        // Ajout du tableau HTML au formulaire
        form.innerHTML = tableHTML
        // Ouverture du modal

        const modal = new bootstrap.Modal(
            document.getElementById('createCashRegisterItemsModal')
        )
        modal.show()
    }

    async createCashRegisterItems() {
        const checkboxes = document.querySelectorAll('.modal-checkbox:checked')
        // const ingredientIds = Array.from(checkboxes).map(checkbox => checkbox.dataset.ingredientId);
        const selectedItems = Array.from(checkboxes).map((checkbox) => {
            const row = checkbox.closest('.modal-create-cash-register-item')
            const vatSelect = row.querySelector('.vat-select')
            const name = row.querySelector('.editable-name')
            const primaryCategorySelect = row.querySelector(
                '.primary-category-select'
            )
            const secondaryCategorySelect = row.querySelector(
                '.secondary-category-select'
            )

            return {
                ingredient_id: checkbox.dataset.ingredientId,
                name: name.value,
                item_type: vatSelect.value,
                primary_cash_register_category_id: primaryCategorySelect.value,
                secondary_cash_register_category_id:
                    secondaryCategorySelect.value,
            }
        })
        const body = JSON.stringify({ selected_items: selectedItems })
        let url = '/cash_register_items/create_batch'
        if (document.body.dataset.locale) {
            url = `/${document.body.dataset.locale}${url}`
        }
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
                    .content,
            },
            body,
        })

        if (response.ok) {
            // ####### si actif = fermeture du modal ####
            // const modalElement = document.getElementById('createCashRegisterItemsModal');
            // const modalInstance = bootstrap.Modal.getInstance(modalElement);
            // modalInstance.hide();
            // réaliser un hard refrsh (true), si soft voulu (donc vide pas le cache, alors ne rien mettre dans () )
            location.reload(true)
        } else {
            const responseText = await response.text()
            console.log(responseText)
        }
    }

    changeSelection() {
        console.log('changeSelection')
        const rows = document.querySelectorAll(
            '.modal-create-cash-register-item'
        )
        console.log('rows', rows)
        let allRowsValid = true

        rows.forEach((row) => {
            const checkbox = row.querySelector('.modal-checkbox')
            console.log(checkbox)
            const vatSelect = row.querySelector('.vat-select')
            console.log(vatSelect)
            const primaryCategorySelect = row.querySelector(
                '.primary-category-select'
            )
            const name = row.querySelector('.editable-name')
            console.log(primaryCategorySelect)

            if (checkbox.checked) {
                if (
                    vatSelect.value === '' ||
                    primaryCategorySelect.value === '' ||
                    name.value === ''
                ) {
                    allRowsValid = false
                }
            }
        })

        const confirmButton = document.getElementById('confirmButton')
        confirmButton.disabled = !allRowsValid
    }

    async updateSecondaryCategories(event) {
        console.log('updateSecondaryCategories')
        const primarySelect = event.target
        const row = primarySelect.closest('.modal-create-cash-register-item')
        const secondarySelect = row.querySelector('.secondary-category-select')
        const primaryCategoryId = primarySelect.value

        // Rendre le select des sous-catégories non accessible pendant le chargement
        secondarySelect.disabled = true
        let url = `/primary_cash_register_categories/${primaryCategoryId}/filter_secondary`
        if (document.body.dataset.locale) {
            url = `/${document.body.dataset.locale}${url}`
        }
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
                    .content,
            },
        })

        if (response.ok) {
            const { secondary_categories } = await response.json()
            let optionsHTML =
                '<option value="">-- Sélectionnez une sous-catégorie --</option>'

            for (const category of secondary_categories) {
                optionsHTML += `<option value="${category.id}">${category.name}</option>`
            }

            secondarySelect.innerHTML = optionsHTML
            secondarySelect.disabled = false
        } else {
            console.error('Erreur lors du chargement des sous-catégories.')
        }
    }
}
