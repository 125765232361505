import { Controller } from "@hotwired/stimulus"
import { Tooltip } from "bootstrap";

// Connects to data-controller="ingredient-photo"
export default class extends Controller {
  static targets = ["select", "warning"];

  connect() {
    console.log("Connected to ingredient-photo controller");
    console.log(this.selectTargets);
    console.log(this.warningTargets);
    this.initializeTooltips();
    this.updateIngredientOptions();
    this.selectTargets.forEach(select => {
      this.showIngredientWarning(select);
    });
  }

  initializeTooltips() {
    console.log("Initializing tooltips");
    this.tooltipList = Array.from(this.element.querySelectorAll('[data-bs-toggle="tooltip"]')).map(tooltipTriggerEl => {
      return new Tooltip(tooltipTriggerEl);
    });
  }

  updateIngredientOptions() {
    console.log("Updating ingredient options");
    let selectedValues = this.selectTargets.map(select => select.value).filter(value => value);
    this.selectTargets.forEach(select => {
      let currentValue = select.value;
      let options = select.querySelectorAll('option');
      options.forEach(option => {
        if (option.value && option.value !== currentValue && selectedValues.includes(option.value)) {
          option.disabled = true;
        } else {
          option.disabled = false;
        }
      });
      select.value = currentValue; // Reassign the current value in case it was disabled
    });
  }

  showIngredientWarning(select) {
    console.log("Showing ingredient warning");
    let index = select.dataset.index;
    let selectedValue = select.value;
    let warningDiv = this.warningTargets[index];
    if (selectedValue) {
      let url = `/ingredients/${selectedValue}/check_photo`
      if (document.body.dataset.locale) {
          url = `/${document.body.dataset.locale}${url}`
      }
      fetch(url)
        .then(response => response.json())
        .then(data => {
          if (data.has_photo) {
            warningDiv.innerHTML = `
              <span class="text-danger">
                <i class="fa-solid fa-triangle-exclamation"></i>
                ${data.warning_message}
              </span>
              <span data-bs-toggle="tooltip" data-bs-html="true" data-bs-placement="left" title="<img src='${data.photo_url}' class='img-fluid' alt='${data.ingredient_name}'>">
                <i class="fa-solid fa-image fa-xl text-success me-1"></i>
              </span>
            `;
            this.initializeTooltips(); // Reinitialize tooltips
          } else {
            warningDiv.innerHTML = '';
          }
        });
    } else {
      warningDiv.innerHTML = '';
    }
  }

  selectChanged(event) {
    console.log("Select changed");
    this.updateIngredientOptions();
    this.showIngredientWarning(event.currentTarget);
  }
}
