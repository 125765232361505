// document.addEventListener('DOMContentLoaded', function () {
//     var tooltipTriggerList = [].slice.call(
//         document.querySelectorAll('[data-bs-toggle="tooltip"]')
//     )
//     var tooltipList = tooltipTriggerList.map(function (element) {
//         return new bootstrap.Tooltip(element)
//     })
// })


document.addEventListener('turbo:load', function () {
  var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
  );
  var tooltipList = tooltipTriggerList.map(function (element) {
      // console.log("Initializing tooltip for", element);
      return new bootstrap.Tooltip(element);
  });
});
