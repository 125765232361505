import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    connect() {
        // Initialize tooltips when the controller is connected
        this.initializeTooltips()

        // Reinitialize tooltips after Turbo Frame is loaded
        document.addEventListener('turbo:frame-load', () => {
            this.initializeTooltips()
        })
    }

    submit(event) {
        this.element.requestSubmit()

        // Reinitialize tooltips after form submission
        this.initializeTooltips()
    }

    initializeTooltips() {
        // Use Bootstrap's API to reinitialize tooltips
        const tooltipTriggerList = document.querySelectorAll(
            '[data-bs-toggle="tooltip"]'
        )
        tooltipTriggerList.forEach((tooltipTriggerEl) => {
            new bootstrap.Tooltip(tooltipTriggerEl)
        })
    }
}
