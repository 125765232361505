import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="price-filter"
export default class extends Controller {
    static targets = [
        'dateRange',
        'priceEvolution',
        'tableBody',
        'compareFirstPrice',
        'supplier',
    ]

    connect() {
        this.loadData()
    }

    loadData() {
        const dateRange = this.dateRangeTarget.value
        const priceEvolution = this.priceEvolutionTarget.value
        const compareFirstPrice = this.compareFirstPriceTarget.checked
            ? '1'
            : '0'
        const supplier = this.supplierTarget.value
        let url = `/dashboard/increases?date_range=${dateRange}&price_evolution=${priceEvolution}&compare_with_first_price=${compareFirstPrice}&supplier=${supplier}`
        if (document.body.dataset.locale) {
            url = `/${document.body.dataset.locale}${url}`
        }
        fetch(
            url,
            {
                method: 'GET',
                headers: {
                    'X-CSRF-Token': document.querySelector(
                        'meta[name=csrf-token]'
                    ).content,
                    Accept: 'text/javascript',
                    'X-Requested-With': 'XMLHttpRequest',
                },
            }
        )
            .then((response) => response.text())
            .then((html) => {
                this.tableBodyTarget.innerHTML = html
            })

            .catch((error) => console.error(error))
    }

    changeLabel() {
        let labelElement = this.element.querySelector(
            'label[for="btn-check-2-outlined"]'
        )
        if (labelElement.textContent.includes('Check vs prix historique')) {
            labelElement.textContent = 'Check vs dernier prix'
        } else {
            labelElement.textContent = 'Check vs prix historique'
        }
    }
}
