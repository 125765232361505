import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="max-hour"
export default class extends Controller {
  static targets = ["input"]

  validate(event) {
    console.log('MaxHourController validate');
    const inputField = event.target;
    let value = inputField.value.trim();

    // Remplacer un point par une virgule
    value = value.replace('.', ',');

    // Appliquer la nouvelle valeur au champ si elle a changé
    if (value !== inputField.value) {
      inputField.value = value;
    }

    // Expression régulière pour une entrée complète : heures (1-24) et minutes (0-100)
    const regexComplete = /^(2[0-4]|1[0-9]|[1-9])(,(0?[1-9]|[1-9][0-9]))?$/;
    // Expression régulière pour une entrée partielle : heures valides suivies d'une virgule ou d'une virgule sans minutes
    const regexPartial = /^(2[0-4]|1[0-9]|[1-9]),?$/;

    // Si des caractères non autorisés sont saisis après les heures ou les minutes, effacer le champ
    const regexInvalid = /[^0-9,]/;

    // Vérification des cas :
    if (regexInvalid.test(value)) {
      inputField.value = ""; // Efface le contenu si des caractères non autorisés sont trouvés
      inputField.classList.add('is-invalid');
      this.showWarning(inputField); // Affiche l'avertissement
    } else if (!regexComplete.test(value) && regexPartial.test(value)) {
      inputField.classList.add('is-invalid');
      this.showWarning(inputField); // Montre l'avertissement tant qu'il n'y a pas de minutes valides après la virgule
    } else if (regexComplete.test(value)) {
      inputField.classList.remove('is-invalid');
      this.hideWarning(inputField); // Cache l'avertissement si la valeur est valide (heures et minutes)
    } else {
      inputField.classList.add('is-invalid');
      this.showWarning(inputField); // Montre l'avertissement pour toute autre erreur
    }
  }

  showWarning(inputField) {
    const warning = inputField.closest('.col-md-12').querySelector('.invalid-feedback');
    warning.style.display = 'block'; // Affiche le message d'erreur
  }

  hideWarning(inputField) {
    const warning = inputField.closest('.col-md-12').querySelector('.invalid-feedback');
    warning.style.display = 'none'; // Cache le message d'erreur
  }
}
