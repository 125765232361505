import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="delivery-copy"
export default class extends Controller {

  copySettings(event) {
    // Sélectionne les jours de livraison et de commande du champ courant
    const currentDeliveryFields = event.currentTarget.closest(".nested-fields-card");
    const deliveryDayInputs = currentDeliveryFields.querySelectorAll('input[type="checkbox"][name*="delivery_day"]');
    const orderDaySelects = currentDeliveryFields.querySelectorAll('select[name*="order_day"]');

    // Pour chaque champ de la page, applique les mêmes réglages
    document.querySelectorAll(".nested-fields-card").forEach((card) => {
      if (card !== currentDeliveryFields) {
        // Copie les jours de livraison
        const targetDeliveryDayInputs = card.querySelectorAll('input[type="checkbox"][name*="delivery_day"]');
        deliveryDayInputs.forEach((input, index) => {
          targetDeliveryDayInputs[index].checked = input.checked;
          targetDeliveryDayInputs[index].dispatchEvent(new Event('change'));  // Déclenche le changement pour Stimulus

          // Mise à jour de l'état des listes déroulantes selon la case à cocher
          this.updateOrderDay(targetDeliveryDayInputs[index]);
        });

        // Copie les jours de commande
        const targetOrderDaySelects = card.querySelectorAll('select[name*="order_day"]');
        orderDaySelects.forEach((select, index) => {
          targetOrderDaySelects[index].value = select.value;
          targetOrderDaySelects[index].dispatchEvent(new Event('change'));  // Déclenche le changement pour Stimulus
        });
      }
    });
  }

  updateOrderDay(dayCheckbox) {
    const orderDayInput = dayCheckbox.closest('tr').querySelector('[data-list]');
    if (dayCheckbox.checked) {
      orderDayInput.disabled = false;
    } else {
      orderDayInput.disabled = true;
      orderDayInput.value = '';
    }
  }
}
