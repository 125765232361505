import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="preview"
export default class extends Controller {
    static targets = ['table']

    removeRow(event) {
        event.preventDefault()

        let row = event.target.closest('tr')
        row.parentNode.removeChild(row)
    }
}
