import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="flatpickr-graph"
export default class extends Controller {
    static targets = ['date']
    connect() {
        flatpickr(this.dateTarget, {
            locale: { firstDayOfWeek: 1 },
            mode: 'range',
            showMonths: 2,
        })
    }
}
