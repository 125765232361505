import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="accordion"
export default class extends Controller {
    connect() {
        this.element
            .querySelectorAll('.accordion-toggle')
            .forEach((trigger) => {
                trigger.addEventListener('click', (e) => {
                    let currentTarget = e.currentTarget
                    let target = document.querySelector(
                        currentTarget.dataset.bsTarget
                    )

                    // Si l'élément cible est déjà ouvert, il sera fermé.
                    if (target.classList.contains('show')) {
                        target.classList.remove('show')
                        e.stopPropagation() // Empêche l'événement de se propager et d'ouvrir à nouveau l'élément.
                    } else {
                        // Ferme tous les éléments ouverts avant d'ouvrir le nouvel élément.
                        this.closeAll()
                        target.classList.add('show')
                    }
                })
            })
    }

    closeAll() {
        this.element.querySelectorAll('.collapse.show').forEach((item) => {
            item.classList.remove('show')
        })
    }
}
