import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="validate-kitchen-form"
export default class extends Controller {
    static targets = ['form']

    connect() {}

    submitForm(event) {
        event.preventDefault()
        const restaurantCheckboxes = document.querySelectorAll(
            '.form-check-input[data-restaurant-id]'
        )

        let shouldSubmitForm = true
        let hasCheckedDeliveryDay = []

        restaurantCheckboxes.forEach((restaurantCheckbox) => {
            const restaurantId =
                restaurantCheckbox.getAttribute('data-restaurant-id')
            const nestedFields = document.querySelectorAll(
                `.nested-fields[data-restaurant-id="${restaurantId}"]`
            )

            if (restaurantCheckbox.checked) {
                const validDaysCheckboxes = this.verifyDaysCheckboxes(
                    nestedFields,
                    hasCheckedDeliveryDay
                )
                shouldSubmitForm = shouldSubmitForm && validDaysCheckboxes
            }
        })

        if (shouldSubmitForm) {
            this.clearErrorMessages()
            this.formTarget.submit()
        }
    }

    verifyDaysCheckboxes(nestedFields, hasCheckedDeliveryDay) {
        let isValid = true

        nestedFields.forEach((field) => {
            const errorMessage = field.querySelector('.error-message')
            const deliveryDayCheckboxes = field.querySelectorAll(
                'input[type="checkbox"][data-action="change->kitchen-delivery-options#select"]'
            )

            const anyDeliveryDayChecked = Array.from(
                deliveryDayCheckboxes
            ).some((ck) => ck.checked)
            hasCheckedDeliveryDay.push(anyDeliveryDayChecked)

            if (
                hasCheckedDeliveryDay.length > 0 &&
                hasCheckedDeliveryDay.every((ele) => ele === true)
            ) {
                errorMessage.style.display = 'none'
                isValid = true
            } else if (anyDeliveryDayChecked) {
                errorMessage.style.display = 'none'
                isValid = false
            } else {
                errorMessage.style.display = 'block'
                isValid = false
            }
        })

        return isValid
    }

    clearErrorMessages() {
        const errorMessages = document.querySelectorAll(
            '.nested-fields .error-message'
        )
        errorMessages.forEach((errorMessage) => {
            errorMessage.style.display = 'none'
        })
    }
}
