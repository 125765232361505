document.addEventListener('turbo:load', () => {
    document
        .querySelectorAll('[data-turbo-frame="supplier"]')
        .forEach((link) => {
            link.addEventListener('click', (event) => {
                document
                    .querySelectorAll('#myTabs .nav-link')
                    .forEach((navLink) => {
                        navLink.classList.remove('active')
                    })
                event.currentTarget.classList.add('active')
            })
        })
})
