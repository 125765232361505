import { Controller } from '@hotwired/stimulus'
import { I18n } from 'i18n-js'
import translations from '../translations.json'

const i18n = new I18n(translations)

const userLocale = document
    .querySelector("meta[name='user-locale']")
    .getAttribute('content')
i18n.locale = userLocale

export default class extends Controller {
    static targets = ['time']

    connect() {
        this.setDefaultTime()
        this.loadChart()
    }

    setDefaultTime() {
        const selectElement = this.element.querySelector('select')
        if (selectElement && selectElement.options.length > 0) {
            selectElement.options[0].selected = true
        }
    }

    updateChart() {
      const selectedPeriod = this.timeTarget.value
      this.chart.showLoading()
      let url = `/dashboard/call_pie_supplier_purchases_service?period=${selectedPeriod}&format=json`
      if (document.body.dataset.locale) {
          url = `/${document.body.dataset.locale}${url}`
      }

      fetch(url, {
          headers: {
              'X-Requested-With': 'XMLHttpRequest',
              'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
                  .content,
          },
      })
          .then((response) => {
              if (!response.ok) {
                  throw new Error('Network response was not ok')
              }
              return response.json()
          })
          .then((receivedData) => {
              const chartData = this.element.querySelector(
                  '#chart-data-dashboard-pie-purchases'
              )
              const nbSuppliersToShow = JSON.parse(
                  chartData.getAttribute('data-nb-max-supplier-to-show')
              )

              receivedData.sort((a, b) => parseFloat(b.y) - parseFloat(a.y))
              const topData = receivedData
                  .slice(0, nbSuppliersToShow)
                  .map((item) => ({
                      name: item.name,
                      y: parseFloat(item.y),
                      visible: true,
                      drilldown: item.drilldown,
                  }))

              const othersTotal = receivedData
                  .slice(nbSuppliersToShow)
                  .reduce((acc, item) => acc + parseFloat(item.y), 0)
              const total = receivedData.reduce(
                  (acc, item) => acc + parseFloat(item.y),
                  0
              )

              // Ensure the chart is initialized before updating
              if (this.chart) {
                  this.chart.series[0].setData(topData, true) // Update the chart data

                  this.chart.update({
                      subtitle: {
                          text:
                              `<br>${i18n.t('i18njs.Total Purchases')}: ` +
                              total.toLocaleString('fr-FR', {
                                  style: 'currency',
                                  currency: 'EUR',
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                              }),
                      },
                  })
              }

              this.chart.hideLoading()
          })
          .catch((error) => {
              console.error('Error updating chart:', error)
          })
  }


    loadChart() {
        const chartData = this.element.querySelector(
            '#chart-data-dashboard-pie-purchases'
        )
        const rawData = JSON.parse(
            chartData.getAttribute('data-purchases-by-supplier-yesterday')
        )
        const nbSuppliersToShow = JSON.parse(
            chartData.getAttribute('data-nb-max-supplier-to-show')
        )
        rawData.sort((a, b) => parseFloat(b.y) - parseFloat(a.y))
        const total = rawData.reduce((acc, item) => acc + parseFloat(item.y), 0)

        const topData = rawData.slice(0, nbSuppliersToShow).map((item) => ({
            name: item.name,
            y: parseFloat(item.y),
            visible: true,
            drilldown: item.drilldown,
        }))

        this.chart = Highcharts.chart('chart-data-dashboard-pie-purchases', {
            chart: {
                type: 'pie',
                style: {
                    width: '100%',
                },
            },
            title: {
                text: null,
            },
            subtitle: {
                text:
                    `<br>${i18n.t('i18njs.Total Purchases')}: ` +
                    total.toLocaleString('fr-FR', {
                        style: 'currency',
                        currency: 'EUR',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                    }),
                align: 'right',
                verticalAlign: 'bottom',
                y: 5,
                style: {
                    color: 'black',
                    fontSize: '14px',
                    fontWeight: 'bold',
                },
            },
            plotOptions: {
                pie: {
                    size: '75%',
                    dataLabels: {
                        enabled: true,
                        format: '{point.name}: {point.y:.0f}€',
                        style: {
                            fontSize: '10px',
                            color:
                                (Highcharts.theme &&
                                    Highcharts.theme.contrastTextColor) ||
                                'black',
                            textOutline: 'none',
                            fontWeight: 'normal',
                        },
                        distance: 15,
                    },
                    showInLegend: true,
                },
            },
            series: [
                {
                    name: i18n.t('i18njs.Purchases'),
                    data: topData,
                },
            ],
            drilldown: {
                series: topData
                    .filter((item) => item.drilldown)
                    .map((item) => ({
                        id: item.name,
                        data: item.drilldown.map((sub) => [sub.name, sub.y]),
                    })),
            },
            tooltip: {
                useHTML: true,
                headerFormat: '',
                pointFormatter: function () {
                    const totalPurchases = Highcharts.numberFormat(
                        this.y,
                        0,
                        ',',
                        '.'
                    )
                    let tooltipHtml = `<span style="font-size:14px">${this.name}: <b>${totalPurchases}€</b></span><br/>`
                    return tooltipHtml
                },
            },
            legend: {
                enabled: false,
            },
            credits: {
                enabled: false,
            },
            exporting: {
                enabled: true,
            },
        })
    }
}
