import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="kitchen-order-index"
export default class extends Controller {
    static targets = ['modalBody']

    loadSelectedOrders() {
        const selectedOrderInputs = document.querySelectorAll(
            "input[name='kitchen_order_ids[]']:checked"
        )
        let content = "<table class='table'>"

        content += `
      <thead>
        <tr>
          <th>Confirm?</th>
          <th>ID</th>
          <th>Restaurant</th>
        </tr>
      </thead>
      <tbody>
    `
        const uniqueDeliveryDates = new Set()
        selectedOrderInputs.forEach((input) => {
            uniqueDeliveryDates.add(input.dataset.deliveryDate)
        })

        uniqueDeliveryDates.forEach((deliveryDate) => {
            const formattedDate = this.formatDateForDataAttribute(deliveryDate)
            content += `<tr class='table-warning'><td colspan='3'>Delivery Date: ${deliveryDate}</td></tr>`

            document
                .querySelectorAll(`tr[data-delivery-date="${formattedDate}"]`)
                .forEach((tr) => {
                    const orderId = tr.dataset.value
                    const restaurantName = tr.dataset.restaurant
                    content += `
                    <tr>
                      <td><input type="checkbox" class="form-check-input" value="${orderId}" checked></td>
                      <td>${orderId}</td>
                      <td>${restaurantName.toUpperCase()}</td>
                    </tr>
                  `
                })
        })
        content += '</tbody></table>'
        this.modalBodyTarget.innerHTML = content
    }

    formatDateForDataAttribute(date) {
        return date.split('-').reverse().join('-')
    }

    submitSelectedOrders() {
        const selectedOrders = this.modalBodyTarget.querySelectorAll(
            "input[type='checkbox']:checked"
        )
        let orderIds = Array.from(selectedOrders).map((order) => order.value)
        let url = '/kitchen_orders/validate_orders_from_restaurants'
        if (document.body.dataset.locale) {
            url = `/${document.body.dataset.locale}${url}`
        }
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector("[name='csrf-token']")
                    .content,
            },
            body: JSON.stringify({ order_ids: orderIds }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.redirect_url) {
                    window.location.href = data.redirect_url
                } else {
                    console.error('Failed to submit orders')
                }
            })
            .catch((error) => console.error('Error:', error))
    }
}
