import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="nested-fields-kitchen"
export default class extends Controller {
    static targets = ['input', 'nestedFields']

    connect() {
        this.updateNestedFields()
    }

    updateNestedFields() {
        const checkboxes = document.querySelectorAll(
            '.form-check-input[data-restaurant-id]'
        )

        const allCheckboxesUnchecked = Array.from(checkboxes).every(
            (cb) => !cb.checked
        )

        checkboxes.forEach((checkbox) => {
            const restaurantId = checkbox.getAttribute('data-restaurant-id')
            const nestedFields = document.querySelectorAll(
                `.nested-fields[data-restaurant-id="${restaurantId}"]`
            )
            const flexSwitchCheck = document.querySelector(
                `#flexSwitchCheckDefault_${restaurantId}`
            )
            const nestedFieldCards = document.querySelectorAll(
                `.nested-fields-card[data-restaurant-id="${restaurantId}"]`
            )

            if (!checkbox.checked) {
                nestedFields.forEach((field) => {
                    field.style.display = 'none'
                })
                nestedFieldCards.forEach((field) => {
                    field.style.display = 'none'
                })
                this.uncheckDaysCheckboxes(nestedFields)
            }

            checkbox.addEventListener('change', function () {
                if (checkbox.checked) {
                    flexSwitchCheck.checked = true
                    nestedFields.forEach((field) => {
                        field.style.display = 'block'
                    })
                } else {
                    flexSwitchCheck.checked = false
                    nestedFields.forEach((field) => {
                        field.style.display = 'none'
                    })
                }
                if (nestedFieldCards) {
                    nestedFieldCards.forEach((field) => {
                        field.style.display = checkbox.checked
                            ? 'block'
                            : 'none'
                    })
                }
            })
        })
        this.updateCheckboxValidation(checkboxes)
    }

    uncheckDaysCheckboxes(nestedFields) {
        nestedFields.forEach((field) => {
            const errorMessage = field.querySelector('.error-message')
            const daysCheckboxes = field.querySelectorAll(
                'input[type="checkbox"][data-action="change->kitchen-delivery-options#select"]'
            )
            daysCheckboxes.forEach((dayCheckbox) => {
                dayCheckbox.checked = false
                errorMessage.style.display = 'none'
                this.updateOrderDay(dayCheckbox)
            })
        })
    }

    updateOrderDay(dayCheckbox) {
        const orderDayInput = dayCheckbox
            .closest('tr')
            .querySelector('[data-list]')
        if (dayCheckbox.checked) {
            orderDayInput.disabled = false
        } else {
            orderDayInput.disabled = true
            orderDayInput.value = ''
        }
    }

    updateCheckboxValidation(checkboxes) {
        const errorMessage = document.getElementById('add-error-message')
        if (Array.from(checkboxes).some((cb) => cb.checked)) {
            errorMessage.style.display = 'none'
        } else {
            errorMessage.style.display = 'block'
        }
    }
}
