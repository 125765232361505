import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="batch"
export default class extends Controller {
    static targets = ['quantity', 'lineTotal']

    increment(event) {
        const buttonElement = event.currentTarget
        console.log('buttonElement', buttonElement)
        let quantity = this.quantityFor(event.currentTarget)
        console.log('quantity', quantity)
        quantity += 1
        console.log('quantity', quantity)
        this.setQuantity(event.currentTarget, quantity)
        this.updateQuantity(buttonElement, quantity)
    }

    decrement(event) {
        const buttonElement = event.currentTarget
        let quantity = this.quantityFor(event.currentTarget)
        quantity = Math.max(quantity - 1, 0) // S'assure que la quantité ne soit pas négative.
        this.setQuantity(event.currentTarget, quantity)
        this.updateQuantity(buttonElement, quantity)
    }
    updateQuantity(buttonElement, quantity) {
        const itemName = buttonElement.dataset.itemName
        const itemId = buttonElement.dataset.itemId
        const ingredientId = buttonElement.dataset.ingredientId
        const recipeId = buttonElement.dataset.recipeId
        const restaurantId = buttonElement.dataset.restaurantId
        const itemType = buttonElement.dataset.itemType
        const lastKitchenOrderId = buttonElement.dataset.lastKitchenOrderId
        console.log('itemName', itemName)
        console.log('restaurantId', restaurantId)
        console.log('quantity', quantity)
        console.log('itemId', itemId)
        console.log('itemType', itemType)

        // Ici, vous devez définir l'URL du backend pour la mise à jour.
        let url = `/kitchen_${itemType}_items/${itemId}/update_quantity_validated_by_kitchen`
        if (document.body.dataset.locale) {
            url = `/${document.body.dataset.locale}${url}`
        }
        console.log('url', url)
        // Structure de la requête avec la méthode PUT ou PATCH selon votre implémentation backend.
        fetch(url, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': this.getMetaValue('csrf-token'), // Récupération du token CSRF pour la sécurité.
            },
            body: JSON.stringify({
                item_name: itemName,
                item_ids: itemId,
                restaurant_id: restaurantId,
                quantity: quantity,
                last_kitchen_order_id: lastKitchenOrderId,
                ingredient_id: ingredientId,
                recipe_id: recipeId,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 'success') {
                    const currentItemIds = JSON.parse(
                        buttonElement.dataset.itemId
                    )
                    if (
                        Array.isArray(currentItemIds) &&
                        currentItemIds.length === 0
                    ) {
                        const newItemId = data.item_id // Nouvel ID de l'item reçu du serveur
                        const newItemIdJson = JSON.stringify([newItemId]) // Conversion en chaîne JSON

                        // Mettez à jour le data-item-id pour le bouton d'incrémentation, de décrémentation et le span de quantité
                        const parentElement = buttonElement.closest('td') // Supposons que les éléments soient tous dans le même <td>
                        parentElement
                            .querySelectorAll(
                                '[data-action="click->batch#increment"], [data-action="click->batch#decrement"], [data-batch-target="quantity"]'
                            )
                            .forEach((el) => {
                                el.dataset.itemId = newItemIdJson // Mise à jour avec le nouvel item_id
                            })

                        console.log(
                            'Nouvel itemId assigné à tous les éléments concernés:',
                            newItemId
                        )
                    }
                    // Trouvez le parent tr de buttonElement
                    const rowElement = buttonElement.closest('tr')

                    // Calculez le nouveau total de la ligne en se basant sur les quantités affichées et le pack size
                    let newLineTotal = 0
                    const packSize = parseFloat(buttonElement.dataset.packSize) // Assurez-vous que packSize est correctement défini

                    rowElement
                        .querySelectorAll('[data-batch-target="quantity"]')
                        .forEach((quantityElement) => {
                            const quantity = parseInt(
                                quantityElement.textContent,
                                10
                            ) // Obtenez la quantité de chaque élément
                            newLineTotal += quantity * packSize // Calculez le total en multipliant par le pack size
                        })

                    // Trouvez l'élément qui affiche le total de la ligne et mettez à jour son contenu
                    const lineTotalElement = rowElement.querySelector(
                        '[data-batch-target="lineTotal"]'
                    )
                    if (lineTotalElement) {
                        lineTotalElement.textContent = newLineTotal.toFixed(2) // Mettez à jour avec le nouveau total, formaté en 2 décimales
                    }
                } else {
                    console.error(
                        'Erreur lors de la mise à jour de la quantité',
                        data.message
                    )
                }
            })
    }

    quantityFor(element) {
        // Trouve l'élément de quantité correspondant dans les targets.
        const targetElement = this.quantityTargets.find((t) => {
            return (
                t.dataset.itemName === element.dataset.itemName &&
                t.dataset.restaurantId === element.dataset.restaurantId &&
                t.dataset.itemId === element.dataset.itemId
            )
        })
        return Number(targetElement.textContent)
    }

    setQuantity(element, quantity) {
        // Met à jour l'élément de quantité correspondant dans les targets.
        const targetElement = this.quantityTargets.find((t) => {
            return (
                t.dataset.itemName === element.dataset.itemName &&
                t.dataset.restaurantId === element.dataset.restaurantId &&
                t.dataset.itemId === element.dataset.itemId
            )
        })
        if (targetElement) {
            targetElement.textContent = quantity
        }
    }

    getMetaValue(name) {
        const element = document.head.querySelector(`meta[name="${name}"]`)
        return element.getAttribute('content')
    }
}
