import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    // Utilisation de requestAnimationFrame pour s'assurer que l'élément est complètement rendu
    requestAnimationFrame(() => {
      this.checkTruncate();
    });
  }

  checkTruncate() {
    const element = this.element;
    const computedStyle = window.getComputedStyle(element);
    const paddingLeft = parseFloat(computedStyle.paddingLeft);
    const paddingRight = parseFloat(computedStyle.paddingRight);

    // Largeur de l'élément visible
    const actualWidth = element.getBoundingClientRect().width;

    // Largeur du contenu sans tenir compte du visible
    const textWidth = this.calculateTextWidth(element);

    // Utilisation de textWidth pour une meilleure précision sur la largeur du texte
    const isTruncated = actualWidth <= textWidth + 10;

    if (isTruncated) {
      element.setAttribute('data-bs-toggle', 'tooltip');
      this.initializeTooltip(element);
    } else {
      element.removeAttribute('data-bs-toggle');
      this.disposeTooltip(element);
    }
  }

  calculateTextWidth(element) {
    // Crée un élément temporaire pour mesurer la largeur du texte
    const tempSpan = document.createElement('span');
    tempSpan.style.visibility = 'hidden';
    tempSpan.style.whiteSpace = 'nowrap';
    tempSpan.style.font = window.getComputedStyle(element).font;
    tempSpan.textContent = element.textContent;

    document.body.appendChild(tempSpan);
    const width = tempSpan.getBoundingClientRect().width;
    document.body.removeChild(tempSpan);

    return width;
  }

  initializeTooltip(element) {
    const existingTooltip = bootstrap.Tooltip.getInstance(element);
    if (!existingTooltip) {
      new bootstrap.Tooltip(element);
    }
  }

  disposeTooltip(element) {
    const tooltipInstance = bootstrap.Tooltip.getInstance(element);
    if (tooltipInstance) {
      tooltipInstance.dispose();
    }
  }
}
