// app/javascript/controllers/flatpickr_controller.js
import { Controller } from '@hotwired/stimulus'
import { createPopper } from '@popperjs/core'
import flatpickr from 'flatpickr'
import { I18n } from 'i18n-js'
import translations from '../translations.json'

const i18n = new I18n(translations)
const userLocale = document
    .querySelector("meta[name='user-locale']")
    .getAttribute('content')
i18n.locale = userLocale

// Connects to data-controller="flatpickr-kitchen"
export default class extends Controller {
    // Inform the controller that it has two targets in the form, which are our inputs
    static targets = [
        'date',
        'kitchen',
        'restaurant',
        'orderTitle',
        'holidayWarning',
    ]
    static values = { kitchenId: Number }

    connect() {
        // flatpickr(this.dateTarget, {})
        this.datepicker = flatpickr(this.dateTarget, {
            inline: true,
            showMonths: 1,
            appendTo: document.getElementById('flatpickr-container'),

            locale: {
                firstDayOfWeek: 1,
            },
        })
        this.changeChoiceOfRestaurant()
    }

    changeChoiceOfRestaurant() {
        const restaurantValue = this.restaurantTarget.value
        // const flatpickrContainer = document.getElementById("flatpickr-container");
        // flatpickrContainer.classList.add("disabled-flatpickr");
        this.datepicker.clear()
        this.datepicker.set('enable', [])
        // this.dateTarget.disabled = true;
        if (restaurantValue === '') {
            this.kitchenTarget.disabled = true
        } else {
            if (this.kitchenTarget.disabled === true) {
                this.kitchenTarget.disabled = false
            }
            this.updateSupplierList()
            this.updateTitleOfOrder()
        }
    }

    changeSupplierChoice() {
        const kitchenId = this.kitchenTarget.value
        const restaurantId = this.restaurantTarget.value
        // const flatpickrContainer = document.getElementById("flatpickr-container");

        if (this.kitchenTarget.value) {
            let url = `/kitchen_restaurant_deliveries/delivery_dates?kitchen_id=${kitchenId}&restaurant_id=${restaurantId}`
            if (document.body.dataset.locale) {
                url = `/${document.body.dataset.locale}${url}`
            }
            fetch(
                url
            )
                .then((response) => response.json())
                .then((dates) => {
                    // this.dateTarget.disabled = false
                    this.datepicker.set('enable', dates)
                    this.datepicker.clear()
                    // flatpickrContainer.classList.remove("disabled-flatpickr");
                    this.updateTitleOfOrder()
                })
        }
    }

    changeDeliveryDate() {
        console.log('change delivery date')
        // this.dateTarget.disabled = false
        // this.dateTarget.value = "";
        const nbDaysAroundHolidayDays = parseInt(
            this.holidayWarningTarget.dataset.daysCheckAroundHolidayDay
        )
        const originalSelectedDate = new Date(this.dateTarget.value)
        // Check if the date is valid
        if (isNaN(originalSelectedDate)) {
            return // go out of the function if the date is not valid
        }

        const startDate = new Date(originalSelectedDate)
        startDate.setDate(startDate.getDate() - nbDaysAroundHolidayDays)

        const endDate = new Date(originalSelectedDate)
        endDate.setDate(endDate.getDate() + nbDaysAroundHolidayDays)

        const holidayDays = JSON.parse(
            this.holidayWarningTarget.dataset.holidayDays
        )

        let currentDate = new Date(startDate)
        while (currentDate <= endDate) {
            let day = currentDate.getDate().toString().padStart(2, '0')
            let month = (currentDate.getMonth() + 1).toString().padStart(2, '0') // +1 car les mois commencent à 0
            let year = currentDate.getFullYear().toString().slice(-2) // Prendre les deux derniers chiffres
            const formattedCurrentDate = `${day}-${month}-${year}`
            if (holidayDays.includes(formattedCurrentDate)) {
                this.holidayWarningTarget.innerHTML = i18n.t(
                    'your_translation_key',
                    { date: formattedCurrentDate }
                )
            }
            currentDate.setDate(currentDate.getDate() + 1)
        }
        this.updateTitleOfOrder()
    }

    updateSupplierList() {
        console.log('update kitchen list')
        const restaurantId = this.restaurantTarget.value
        console.log(restaurantId)
        let url = `/accessible_kitchens?restaurant_id=${restaurantId}`
        if (document.body.dataset.locale) {
            url = `/${document.body.dataset.locale}${url}`
        }
        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                this.kitchenTarget.innerHTML = '' // Vide la liste actuelle

                // Ajoute une option vide au début
                const blankOption = document.createElement('option')
                blankOption.value = ''
                blankOption.text = '-- Select Supplier --'
                blankOption.setAttribute('disabled', 'disabled')
                blankOption.setAttribute('selected', 'selected')
                this.kitchenTarget.add(blankOption)

                // Ajoute les fournisseurs à la liste
                data.forEach((kitchen) => {
                    const option = document.createElement('option')
                    option.value = kitchen.id
                    option.text = kitchen.name
                    this.kitchenTarget.add(option)
                })
            })
    }

    updateTitleOfOrder() {
        const restaurantName = this.restaurantTarget.selectedOptions[0].text
        const titleInput = this.orderTitleTarget
        titleInput.value = `Order from ${restaurantName}`

        // Récupération et formatage de la date de livraison
        const deliveryDate = new Date(this.dateTarget.value)
        if (isNaN(deliveryDate)) {
            return // go out of the function if the date is not valid
        } else {
            const deliveryDay = String(deliveryDate.getDate()).padStart(2, '0')
            const deliveryMonth = String(deliveryDate.getMonth() + 1).padStart(
                2,
                '0'
            )
            const deliveryYear = deliveryDate.getFullYear()
            const formattedDeliveryDate = `${deliveryDay}-${deliveryMonth}-${deliveryYear}`
            titleInput.value = `Order from ${restaurantName} - Delivery on : ${formattedDeliveryDate}`
        }
    }
}
