import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="kitchen-modal-ingredients"
export default class extends Controller {
    selectedIngredients = []
    static targets = [
        'totalOrderPrice',
        'francoSubmit',
        'orderFields',
        'destroyLink',
        'totalCell',
        'totalQuantity',
    ]

    connect() {
        console.log('Connected to kitchen-modal-ingredients controller')
        const franco = this.element.dataset.franco
        this.updateTotalPrice(franco)
    }

    submitRecipe() {
        const kitchenOrderId = this.element.dataset.kitchenOrderId
        const selectedRecipes = Array.from(
            document.querySelectorAll(
                ".modal-body .form-check-input[type='checkbox']:checked"
            )
        )
        const orderFields = this.orderFieldsTarget
        selectedRecipes.forEach((checkbox) => {
            const selectedRecipeId = checkbox.value
            console.log('selectedRecipeId', selectedRecipeId)
            checkbox.checked = false
            let url = `/kitchen_orders/${kitchenOrderId}/kitchen_recipe_items`
            if (document.body.dataset.locale) {
                url = `/${document.body.dataset.locale}${url}`
            }
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': document.querySelector(
                        'meta[name=csrf-token]'
                    ).content,
                },
                body: JSON.stringify({ selected_recipe_id: selectedRecipeId }),
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.status === 'success') {
                        // Ajouter la ligne au tableau HTML pour l'affichage
                        const newRow = orderFields.insertRow()
                        newRow.id = `recipe-row-${data.recipe_item_id}`
                        const cell1 = newRow.insertCell(0)
                        const cell2 = newRow.insertCell(1)
                        const cell3 = newRow.insertCell(2)
                        const cell4 = newRow.insertCell(3)
                        const cell5 = newRow.insertCell(4)
                        const cell6 = newRow.insertCell(5)
                        const cell7 = newRow.insertCell(6)
                        const cell8 = newRow.insertCell(7)

                        cell1.style.width = '40%'
                        cell2.style.width = '7%'
                        cell3.style.width = '7%'
                        cell4.style.width = '7%'
                        cell5.style.width = '18%'
                        cell6.style.width = '7%'
                        cell7.style.width = '7%'
                        cell8.style.width = '7%'

                        let roundedWeight = parseFloat(
                            data.weight_by_item
                        ).toFixed(2)
                        let icon = document.createElement('i')
                        icon.className = 'fas fa-book me-1 text-warning' // Utilisez la classe réelle de votre icône
                        let textSpan = document.createElement('span')
                        textSpan.innerText = ` ${data.name} (${roundedWeight} kg/PC)`
                        cell1.innerHTML = ''
                        cell1.appendChild(icon)
                        cell1.appendChild(textSpan)

                        let unitPrice = data.price_per_piece_cents / 100
                        let formattedUnitPrice = unitPrice.toLocaleString(
                            'fr-FR',
                            { style: 'currency', currency: 'EUR' }
                        )
                        formattedUnitPrice = formattedUnitPrice.replace(
                            /([\d,]+)\s€/,
                            '€$1'
                        )
                        cell2.innerText = `${formattedUnitPrice}`
                        cell2.className = 'text-center'

                        cell3.innerText = data.pack_size
                        cell3.className = 'text-center'

                        cell4.innerText = 'PC'
                        cell4.className = 'text-center'

                        const quantityInput = document.createElement('input')
                        quantityInput.type = 'number'
                        quantityInput.className =
                            'form-control form-control-sm text-center'
                        quantityInput.min = 0
                        quantityInput.value = 0
                        quantityInput.dataset.action =
                            'change->kitchen-modal-ingredients#updateQuantity'
                        quantityInput.dataset.recipeItemId = data.recipe_item_id
                        quantityInput.dataset.itemId = data.recipe_item_id
                        quantityInput.dataset.recipeId = data.recipe_id
                        quantityInput.dataset.itemType = 'recipe'
                        cell5.appendChild(quantityInput)

                        let totalQuantity = 0
                        cell6.innerText = totalQuantity
                        cell6.dataset.recipeItemId = data.recipe_item_id
                        cell6.dataset.itemId = data.recipe_item_id
                        cell6.dataset.kitchenModalIngredientsTarget =
                            'totalQuantity'
                        cell6.className = 'text-center'

                        let total = (0).toLocaleString('fr-FR', {
                            style: 'currency',
                            currency: 'EUR',
                        })
                        cell7.innerText = total.replace(/([\d,]+)\s€/, '€$1')
                        cell7.dataset.kitchenModalIngredientsTarget =
                            'totalCell'
                        cell7.dataset.recipeItemId = data.recipe_item_id
                        cell7.dataset.itemId = data.recipe_item_id
                        cell7.className = 'text-center'

                        const destroyLink = document.createElement('a')
                        destroyLink.innerHTML =
                            '<i class="fa-solid fa-trash"></i>'
                        destroyLink.href = '#'
                        destroyLink.dataset.kitchenModalIngredientsTarget =
                            'destroyLink'
                        destroyLink.dataset.action =
                            'click->kitchen-modal-ingredients#deleteItem'
                        destroyLink.dataset.recipeItemId = data.recipe_item_id
                        destroyLink.dataset.itemId = data.recipe_item_id
                        destroyLink.dataset.recipeId = data.recipe_id
                        destroyLink.dataset.itemType = 'recipe'
                        destroyLink.dataset.confirm = 'Are you sure ?'
                        cell8.className = 'text-center'
                        cell8.appendChild(destroyLink)

                        const correspondingDiv = document.querySelector(
                            `[data-recipe-modal-id="recipeModalDiv_${selectedRecipeId}"]`
                        )
                        if (correspondingDiv) {
                            correspondingDiv.classList.add('d-none')
                        }
                    }
                })
                .catch((error) => {
                    console.error('Error:', error)
                })

            this.indexCounter++
        })
    }
    submitIngredient() {
        const kitchenOrderId = this.element.dataset.kitchenOrderId
        const selectedIngredients = Array.from(
            document.querySelectorAll(
                ".modal-body-ingredient .form-check-input[type='checkbox']:checked"
            )
        )
        const orderFields = this.orderFieldsTarget
        selectedIngredients.forEach((checkbox) => {
            const selectedIngredientId = checkbox.value
            console.log('selectedIngredientId', selectedIngredientId)
            checkbox.checked = false
            let url = `/kitchen_orders/${kitchenOrderId}/kitchen_ingredient_items`
            if (document.body.dataset.locale) {
                url = `/${document.body.dataset.locale}${url}`
            }
            fetch(
                url,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-Token': document.querySelector(
                            'meta[name=csrf-token]'
                        ).content,
                    },
                    body: JSON.stringify({
                        selected_ingredient_id: selectedIngredientId,
                    }),
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    if (data.status === 'success') {
                        const newRow = orderFields.insertRow()
                        newRow.id = `ingredient-row-${data.ingredient_item_id}`
                        const cell1 = newRow.insertCell(0)
                        const cell2 = newRow.insertCell(1)
                        const cell3 = newRow.insertCell(2)
                        const cell4 = newRow.insertCell(3)
                        const cell5 = newRow.insertCell(4)
                        const cell6 = newRow.insertCell(5)
                        const cell7 = newRow.insertCell(6)
                        const cell8 = newRow.insertCell(7)

                        cell1.style.width = '40%'
                        cell2.style.width = '7%'
                        cell3.style.width = '7%'
                        cell4.style.width = '7%'
                        cell5.style.width = '18%'
                        cell6.style.width = '7%'
                        cell7.style.width = '7%'
                        cell8.style.width = '7%'

                        cell1.innerHTML =
                            data.name.charAt(0).toUpperCase() +
                            data.name.slice(1)

                        let unitPrice = data.unit_price / 100
                        let formattedUnitPrice = unitPrice.toLocaleString(
                            'fr-FR',
                            { style: 'currency', currency: 'EUR' }
                        )
                        formattedUnitPrice = formattedUnitPrice.replace(
                            /([\d,]+)\s€/,
                            '€$1'
                        )
                        cell2.innerText = `${formattedUnitPrice}`
                        cell2.className = 'text-center'

                        cell3.innerText = data.pack_size
                        cell3.className = 'text-center'

                        cell4.innerText = data.unit_weight
                        cell4.className = 'text-center'

                        const quantityInput = document.createElement('input')
                        quantityInput.type = 'number'
                        quantityInput.className =
                            'form-control form-control-sm text-center'
                        quantityInput.min = 0
                        quantityInput.value = 0
                        quantityInput.dataset.action =
                            'change->kitchen-modal-ingredients#updateQuantity'
                        quantityInput.dataset.ingredientItemId =
                            data.ingredient_item_id
                        quantityInput.dataset.itemId = data.ingredient_item_id
                        quantityInput.dataset.ingredientId = data.ingredient_id
                        quantityInput.dataset.itemType = 'ingredient'
                        cell5.appendChild(quantityInput)

                        let totalQuantity = 0
                        cell6.innerText = totalQuantity
                        cell6.dataset.ingredientItemId = data.ingredient_item_id
                        cell6.dataset.itemId = data.ingredient_item_id
                        cell6.dataset.kitchenModalIngredientsTarget =
                            'totalQuantity'
                        cell6.className = 'text-center'

                        let total = (0).toLocaleString('fr-FR', {
                            style: 'currency',
                            currency: 'EUR',
                        })
                        cell7.innerText = total.replace(/([\d,]+)\s€/, '€$1')
                        cell7.dataset.kitchenModalIngredientsTarget =
                            'totalCell'
                        cell7.dataset.ingredientItemId = data.ingredient_item_id
                        cell7.dataset.itemId = data.ingredient_item_id
                        cell7.className = 'text-center'

                        const destroyLink = document.createElement('a')
                        destroyLink.innerHTML =
                            '<i class="fa-solid fa-trash"></i>'
                        destroyLink.href = '#'
                        destroyLink.dataset.kitchenModalIngredientsTarget =
                            'destroyLink'
                        destroyLink.dataset.action =
                            'click->kitchen-modal-ingredients#deleteItem'
                        destroyLink.dataset.ingredientItemId =
                            data.ingredient_item_id
                        destroyLink.dataset.itemId = data.ingredient_item_id
                        destroyLink.dataset.ingredientId = data.ingredient_id
                        destroyLink.dataset.itemType = 'ingredient'
                        destroyLink.dataset.confirm = 'Are you sure ?'
                        cell8.className = 'text-center'
                        cell8.appendChild(destroyLink)

                        const correspondingDiv = document.querySelector(
                            `[data-ingredient-modal-id="ingredientModalDiv_${selectedIngredientId}"]`
                        )
                        if (correspondingDiv) {
                            correspondingDiv.classList.add('d-none')
                        }
                    }
                })
                .catch((error) => {
                    console.error('Error:', error)
                })

            this.indexCounter++
        })
    }

    updateQuantity(event) {
        console.log(this.element)
        console.log(event.currentTarget)
        const itemType = event.currentTarget.dataset.itemType
        console.log('itemType', itemType)
        const itemId = event.currentTarget.dataset.itemId
        const recipeOrIngredientId =
            event.currentTarget.dataset[`${itemType}Id`]
        console.log('itemId', itemId)
        const quantity = event.currentTarget.value

        console.log(quantity)
        console.log('this.totalCellTargets', this.totalCellTargets)
        const targetAttribute =
            itemType === 'ingredient' ? 'ingredientItemId' : 'recipeItemId'
        console.log('targetAttribute', targetAttribute)
        const totalCell = this.totalCellTargets.find(
            (cell) => cell.dataset[targetAttribute] == itemId
        )
        const totalQuantity = this.totalQuantityTargets.find(
            (cell) => cell.dataset[targetAttribute] == itemId
        )
        console.log('totalCell', totalCell)
        console.log('totalQuantity', totalQuantity)
        let url =
            itemType === 'ingredient'
                ? `/kitchen_ingredient_items/${itemId}`
                : `/kitchen_recipe_items/${itemId}`
        if (document.body.dataset.locale) {
            url = `/${document.body.dataset.locale}${url}`
        }
        fetch(url, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
                    .content,
            },
            body: JSON.stringify({
                id: itemId,
                quantity: quantity,
                recipe_or_ingredient_id: recipeOrIngredientId,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log('success qty update')
                const unitPrice = parseFloat(data.unit_price / 100)
                console.log('unitPrice', unitPrice)
                console.log('quantity', typeof quantity)
                console.log('unitPrice', typeof unitPrice)
                const total = unitPrice * quantity
                console.log('total', total)
                let formattedTotal = total.toLocaleString('fr-FR', {
                    style: 'currency',
                    currency: 'EUR',
                })
                formattedTotal = formattedTotal.replace(/([\d,]+)\s€/, '€$1')
                totalCell.textContent = formattedTotal
                console.log('totalQuantity', data.total_item_quantity)
                totalQuantity.textContent = data.total_item_quantity
                this.updateTotalPrice(data.franco)
            })
            .catch((error) => {
                console.error('Error:', error)
            })
    }

    updateTotalPrice(franco) {
        console.log('updateTotalPrice')
        console.log('franco', franco)
        console.log('type of franco', typeof franco)
        console.log('franco', parseFloat(franco))
        console.log('type of franco', typeof parseFloat(franco))
        let totalOrder = 0
        this.totalCellTargets.forEach((row) => {
            totalOrder += parseFloat(row.textContent.replace('€', '').trim())
        })
        this.totalOrderPriceTarget.innerHTML = `Franco: €${totalOrder.toFixed(2)} / €${parseFloat(franco).toFixed(2)}`
        if (totalOrder >= franco) {
            this.totalOrderPriceTarget.classList.add('btn-success')
            this.totalOrderPriceTarget.classList.remove('btn-danger')
        } else if (totalOrder < franco) {
            this.totalOrderPriceTarget.classList.remove('btn-success')
            this.totalOrderPriceTarget.classList.add('btn-danger')
        }
        this.updateSenderOrderButton(totalOrder, franco)
    }

    updateSenderOrderButton(totalOrder, franco) {
        let isQuantityZero = false
        let elements = document.querySelectorAll('.ingredient-quantity')
        elements.forEach((element) => {
            let input_value = parseInt(element.value, 10)
            if (input_value === 0) {
                isQuantityZero = true
                element.classList.add('bg-danger', 'bg-opacity-25')
            } else {
                element.classList.remove('bg-danger', 'bg-opacity-25')
            }
        })

        let sendOrderButtons = document.querySelectorAll('.send-order')
        sendOrderButtons.forEach((button) => {
            if (isQuantityZero) {
                button.classList.add('disabled')
            } else {
                button.classList.remove('disabled')
            }
        })

        if (totalOrder >= franco && !isQuantityZero) {
            this.francoSubmitTarget.classList.remove('disabled')
        } else {
            this.francoSubmitTarget.classList.add('disabled')
        }
    }

    deleteItem(event) {
        event.preventDefault()
        if (!confirm(event.currentTarget.dataset.confirm)) {
            return
        }

        const itemId = event.currentTarget.dataset.itemId
        const itemType = event.currentTarget.dataset.itemType
        const targetAttribute =
            itemType === 'ingredient' ? 'ingredientItemId' : 'recipeItemId'
        const destroyCell = this.destroyLinkTargets.find(
            (cell) => cell.dataset[targetAttribute] == itemId
        )
        const recipeOrIngredientId =
            itemType === 'ingredient'
                ? destroyCell.dataset.ingredientId
                : destroyCell.dataset.recipeId
        let url =
            itemType === 'ingredient'
                ? `/kitchen_ingredient_items/${itemId}`
                : `/kitchen_recipe_items/${itemId}`
        if (document.body.dataset.locale) {
            url = `/${document.body.dataset.locale}${url}`
        }
        fetch(url, {
            method: 'DELETE',
            headers: {
                'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
                    .content,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 'success') {
                    console.log('success delete')
                    const selector = `[data-recipe-modal-id="recipeModalDiv_${recipeOrIngredientId}"]`
                    console.log(
                        "Recherche de l'élément avec le sélecteur: ",
                        selector
                    )
                    const correspondingDiv =
                        itemType === 'ingredient'
                            ? document.querySelector(
                                  `[data-ingredient-modal-id="ingredientModalDiv_${recipeOrIngredientId}"]`
                              )
                            : document.querySelector(
                                  `[data-recipe-modal-id="recipeModalDiv_${recipeOrIngredientId}"]`
                              )
                    console.log('correspondingDiv', correspondingDiv)
                    if (correspondingDiv) {
                        correspondingDiv.classList.remove('d-none')
                    }
                    const row =
                        itemType === 'ingredient'
                            ? document.getElementById(
                                  `ingredient-row-${itemId}`
                              )
                            : document.getElementById(`recipe-row-${itemId}`)
                    if (row) {
                        row.remove()
                    }
                } else {
                    console.error('Erreur lors de la suppression')
                }
                this.updateModalList()
                this.updateTotalPrice(data.franco)
            })
            .catch((error) => {
                console.error('Erreur:', error)
            })
    }

    updateModalList() {
        console.log('updateModalList')
        const kitchenOrderId = this.element.dataset.kitchenOrderId
        let url = `/kitchen_orders/${kitchenOrderId}`
        if (document.body.dataset.locale) {
            url = `/${document.body.dataset.locale}${url}`
        }
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
                    .content,
            },
        })
            .then((response) => response.text())
            .then((html) => {
                console.log(
                    'Mettre à jour le DOM avec les nouveaux contenu des modals'
                )
                const parser = new DOMParser()
                const doc = parser.parseFromString(html, 'text/html')

                // Mettre à jour le modal des recettes
                const recipeModalContent =
                    doc.querySelector('#recipesModal').outerHTML
                document.querySelector('#recipesModal').outerHTML =
                    recipeModalContent

                // Mettre à jour le modal des ingrédients (ajustez l'ID selon votre mise en page)
                const ingredientModalContent =
                    doc.querySelector('#ingredientsModal').outerHTML
                document.querySelector('#ingredientsModal').outerHTML =
                    ingredientModalContent
            })
            .catch((error) => {
                console.error('Erreur:', error)
            })
    }
}
