import { Controller } from '@hotwired/stimulus'
import { I18n } from 'i18n-js'
import translations from '../translations.json'

const i18n = new I18n(translations)

const userLocale = document
    .querySelector("meta[name='user-locale']")
    .getAttribute('content')
i18n.locale = userLocale

// Connects to data-controller="chart-turnover"
export default class extends Controller {
    connect() {
        console.log('highcharts turnover')
        this.loadChart()
    }

    loadChart() {
        const chartData = this.element.querySelector('#chart-data')
        const categoriesData = JSON.parse(
            chartData.getAttribute('data-categories')
        )
        const turnoverSeriesData = JSON.parse(
            chartData.getAttribute('data-turnover-series')
        )
        const marginEuroSeriesData = JSON.parse(
            chartData.getAttribute('data-margin-euro-series')
        )
        const marginPercentageSeriesData = JSON.parse(
            chartData.getAttribute('data-margin-percentage-series')
        )

        Highcharts.chart('chart-data', {
            chart: {
                type: 'column',
            },
            title: {
                text: null,
            },
            xAxis: {
                categories: categoriesData,
                crosshair: true,
            },
            yAxis: [
                {
                    // Primary yAxis for Turnover and Margin in €
                    title: {
                        text: i18n.t('i18njs.Turnover (€)'),
                    },
                    opposite: false,
                },
                {
                    // Secondary yAxis for Margin in %
                    title: {
                        text: i18n.t('i18njs.Margin (%)'),
                    },
                    opposite: true,
                    labels: {
                        format: '{value} %',
                    },
                },
            ],
            tooltip: {
                shared: true,
                useHTML: true,
                formatter: function () {
                    let tooltipHtml =
                        '<span style="font-size:10px">' +
                        this.key +
                        '</span><table>'
                    this.points.forEach((point) => {
                        tooltipHtml +=
                            '<tr><td style="color:' +
                            point.series.color +
                            ';padding:0">' +
                            point.series.name +
                            ': </td>' +
                            '<td style="padding:0"><b>' +
                            (point.series.name === i18n.t('i18njs.Margin (%)')
                                ? point.y.toFixed(2) + ' %'
                                : point.y.toLocaleString('fr-FR', {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 0,
                                  }) + ' €') +
                            '</b></td></tr>'
                    })

                    // Ajouter les informations de "Marge (€)" dans le tooltip
                    // Utiliser les données de marginEuroSeriesData en fonction de l'index de la colonne
                    const index = this.points[0].point.index // Obtenir l'index de la colonne actuelle
                    const marginEuroValue = marginEuroSeriesData[index] // Récupérer la valeur correspondante dans marginEuroSeriesData
                    if (marginEuroValue !== undefined) {
                        tooltipHtml +=
                            '<tr><td style="color:' +
                            'black' + // Utiliser une couleur par défaut
                            ';padding:0">' +
                            i18n.t('i18njs.Margin (€)') +
                            ': </td>' +
                            '<td style="padding:0"><b>' +
                            marginEuroValue.toLocaleString('fr-FR', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            }) +
                            ' €</b></td></tr>'
                    }

                    tooltipHtml += '</table>'
                    return tooltipHtml
                },
            },

            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                },
            },
            series: [
                {
                    name: i18n.t('i18njs.Turnover by restaurant'),
                    data: turnoverSeriesData,
                    yAxis: 0,
                    type: 'column',
                    dataLabels: {
                        enabled: true,
                        formatter: function () {
                            // Trouver le pourcentage correspondant à l'index de la colonne
                            const percentage =
                                marginPercentageSeriesData[this.point.index]
                            return percentage
                                ? percentage.toFixed(2) + ' %'
                                : ''
                        },
                        style: {
                            fontWeight: 'bold',
                            color: 'black',
                        },
                    },
                },
                // {
                //     name: i18n.t('i18njs.Margin (€)'),
                //     data: marginEuroSeriesData,
                //     yAxis: 0,
                //     type: 'line', // Change le type pour ne pas l'afficher comme une colonne
                //     showInLegend: false, // Ne montre pas la série dans la légende
                //     visible: false, // Ne montre pas la série sur le graphique
                //     enableMouseTracking: true, // Active le suivi de la souris pour le tooltip
                //     tooltip: {
                //         valueSuffix: ' €',
                //     },
                // },

                {
                    name: i18n.t('i18njs.Margin (%)'),
                    type: 'spline',
                    data: marginPercentageSeriesData,
                    yAxis: 1,
                    tooltip: {
                        valueSuffix: ' %',
                    },
                },
            ],
            credits: {
                enabled: false,
            },
        })
    }
}
