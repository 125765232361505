import { Controller } from '@hotwired/stimulus'
// import { I18n } from "i18n-js"
// import translations from "../translations.json";

// const i18n = new I18n(translations);
// const userLocale = document.querySelector("meta[name='user-locale']").getAttribute("content");
// i18n.locale = userLocale;

export default class extends Controller {
    static targets = ['modal']
    static values = { dynamicValue: Number }
    connect() {
        this.initializeCalendarLink()
        document.addEventListener('click', this.documentClickHandler)
        // this.setTodayText();
    }

    disconnect() {
        document.removeEventListener('click', this.documentClickHandler)
    }


    initializeCalendarLink() {
        const currentDate = new Date()
        const year = currentDate.getFullYear()
        const month = String(currentDate.getMonth() + 1).padStart(2, '0')
        const day = String(currentDate.getDate()).padStart(2, '0')
        const formattedDate = `${year}-${month}-${day}`
        const appendElement = `<div class="calendar-heading current-date"><a href="/en/holidays/agenda?start_date=${formattedDate}"></a></div>`
        const calendarHeading = document.querySelector('.simple-calendar')
        calendarHeading.insertAdjacentHTML('afterBegin', appendElement)

        const queryParams = window.location.search
        const current_page_date = queryParams.split('=')[1]

        const current_date_link = document.querySelector(
            '.current-date a:nth-child(1)'
        )
        if (current_page_date === formattedDate) {
            current_date_link.classList.add('disabled')
        } else {
            current_date_link.classList.remove('disabled')
        }

        const anchorTags = document.querySelectorAll('.calendar-heading a')

        anchorTags.forEach((anchorTag) => {
            anchorTag.classList.add('btn', 'btn-primary')
        })
    }

    openModal(event) {
        this.modalTargets.forEach((modal) => {
            modal.style.display = 'none'
        })

        const modalToOpen = event.currentTarget.querySelector('.modal')

        if (modalToOpen) {
            modalToOpen.style.display = 'block'
        }
    }

    closeModal(event) {
        event.preventDefault()
        event.stopPropagation()
        const modal = event.target.closest('.modal')

        if (modal) {
            modal.style.display = 'none'
        }
    }

    documentClickHandler = (event) => {
        if (!event.target.classList.contains('open-more-holidays-modal')) {
            this.hideAllModals()
        }
    }

    hideAllModals() {
        this.modalTargets.forEach((modal) => {
            modal.style.display = 'none'
        })
    }
}
