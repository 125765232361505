import { Controller } from '@hotwired/stimulus'
import { I18n } from 'i18n-js'
import translations from '../translations.json'

const i18n = new I18n(translations)
const userLocale = document
    .querySelector("meta[name='user-locale']")
    .getAttribute('content')
i18n.locale = userLocale

// Connects to data-controller="chart-dashboard-bar-sales"
export default class extends Controller {
    static targets = ['time']

    connect() {
        this.setDefaultTime()
        this.loadChart()
    }

    setDefaultTime() {
        const selectElement = this.element.querySelector('select')
        if (selectElement && selectElement.options.length > 0) {
            selectElement.options[0].selected = true
        }
    }

    updateChart() {
        const selectedPeriod = this.timeTarget.value
        this.chart.showLoading()
        let url = `/dashboard/call_pie_chart_sales_by_plu_service?period=${selectedPeriod}&format=json`
        if (document.body.dataset.locale) {
            url = `/${document.body.dataset.locale}${url}`
        }
        fetch(url, {
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
                    .content,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok')
                }
                return response.json()
            })
            .then((receivedData) => {
                const chartData = this.element.querySelector(
                    '#chart-data-dashboard-pie-sales'
                )
                const nbPoductsToShow = JSON.parse(
                    chartData.getAttribute('data-nb-max-product-to-show')
                )
                receivedData.sort((a, b) => parseFloat(b.y) - parseFloat(a.y))
                const topData = receivedData
                    .slice(0, nbPoductsToShow)
                    .map((item) => ({
                        name: item.name,
                        y: parseFloat(item.y),
                        visible: true,
                    }))

                // Calculer le total pour 'Divers'
                const othersTotal = receivedData
                    .slice(nbPoductsToShow)
                    .reduce((acc, item) => acc + parseFloat(item.y), 0)
                const total = receivedData.reduce(
                    (acc, item) => acc + parseFloat(item.y),
                    0
                )

                // Mise à jour du graphique avec les nouvelles données
                this.chart.update({
                    chart: {
                        type: 'bar', // Changer le type de graphique en "bar"
                    },
                    xAxis: {
                        categories: topData.map((item) => item.name),
                        title: {
                            text: null,
                        },
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: null,
                        },
                        labels: {
                            overflow: 'justify',
                            formatter: function () {
                                return this.value.toLocaleString('fr-FR', {
                                    style: 'currency',
                                    currency: 'EUR',
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                })
                            },
                        },
                    },

                    tooltip: {
                        formatter: function () {
                            const percentageGlobal = (this.y / total) * 100
                            return (
                                this.point.name + // Utilisation correcte du nom de l'article
                                ': <b>' +
                                this.y.toLocaleString('fr-FR', {
                                    style: 'currency',
                                    currency: 'EUR',
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                }) +
                                ' - ' +
                                percentageGlobal.toFixed(1) +
                                '%</b>'
                            )
                        },
                    },
                    plotOptions: {
                        bar: {
                            pointWidth: 20,
                            dataLabels: {
                                enabled: true,
                                inside: true,
                                color: 'white',
                                style: {
                                    fontSize: '10px',
                                    fontWeight: 'bold',
                                    textOutline: 'none',
                                },
                                formatter: function () {
                                    const percentageGlobal =
                                        (this.y / total) * 100
                                    return percentageGlobal.toFixed(1) + '%' // Affiche le même pourcentage que dans le tooltip
                                },
                            },
                        },
                    },

                    series: [
                        {
                            name: null,
                            data: topData.map((item) => item.y),
                        },
                    ],
                    subtitle: {
                        text:
                            `${i18n.t('i18njs.Total out TOP')} ${nbPoductsToShow}: ` +
                            othersTotal.toLocaleString('fr-FR', {
                                style: 'currency',
                                currency: 'EUR',
                            }) +
                            `<br>${i18n.t('i18njs.Turnover')}: ` +
                            total.toLocaleString('fr-FR', {
                                style: 'currency',
                                currency: 'EUR',
                            }),
                        align: 'right',
                        verticalAlign: 'bottom',
                        y: 5,
                        style: {
                            color: 'black',
                            fontSize: '14px',
                            fontWeight: 'bold',
                        },
                    },
                })
                this.chart.hideLoading()
            })
            .catch((error) => {
                console.error('Error updating chart:', error)
            })
    }

    loadChart() {
        const chartData = this.element.querySelector(
            '#chart-data-dashboard-pie-sales'
        )
        const rawData = JSON.parse(
            chartData.getAttribute('data-sales-by-plu-yesterday')
        )
        const nbPoductsToShow = JSON.parse(
            chartData.getAttribute('data-nb-max-product-to-show')
        )
        rawData.sort((a, b) => parseFloat(b.y) - parseFloat(a.y))
        const total = rawData.reduce((acc, item) => acc + parseFloat(item.y), 0)

        const topData = rawData.slice(0, nbPoductsToShow).map((item) => ({
            name: item.name,
            y: parseFloat(item.y),
            visible: true,
        }))

        const othersTotal = rawData
            .slice(nbPoductsToShow)
            .reduce((acc, item) => acc + parseFloat(item.y), 0)

        this.chart = Highcharts.chart('chart-data-dashboard-pie-sales', {
            chart: {
                type: 'bar', // Utilise un graphique à barres
                style: {
                    width: '100%', // Adapte la largeur du graphique au conteneur
                },
            },
            title: {
                text: null,
            },
            subtitle: {
                text:
                    `${i18n.t('i18njs.Total out TOP')} ${nbPoductsToShow}: ` +
                    othersTotal.toLocaleString('fr-FR', {
                        style: 'currency',
                        currency: 'EUR',
                    }) +
                    `<br>${i18n.t('i18njs.Turnover')}: ` +
                    total.toLocaleString('fr-FR', {
                        style: 'currency',
                        currency: 'EUR',
                    }),
                align: 'right',
                verticalAlign: 'bottom',
                y: 5,
                style: {
                    color: 'black',
                    fontSize: '14px',
                    fontWeight: 'bold',
                },
            },
            xAxis: {
                categories: topData.map((item) => item.name), // Affiche les noms des produits sur l'axe de gauche
                title: {
                    text: null,
                },
            },
            yAxis: {
                title: {
                    text: null,
                },
                labels: {
                    format: '{value} €',
                },
                reversed: true, // Inverse l'ordre des catégories pour que les plus grandes valeurs soient en haut
            },
            plotOptions: {
                bar: {
                    pointWidth: 20, // Définit l'épaisseur des barres
                    dataLabels: {
                        enabled: true,
                        inside: true, // Affiche les labels à l'intérieur des barres
                        color: 'white', // Met les labels en blanc
                        style: {
                            fontSize: '10px',
                            fontWeight: 'bold', // Texte en gras
                            textOutline: 'none',
                        },
                        formatter: function () {
                            const percentageGlobal = (this.y / total) * 100
                            return percentageGlobal.toFixed(1) + '%' // Affiche le pourcentage
                        },
                    },
                },
            },
            series: [
                {
                    name: null,
                    data: topData.map((item) => item.y), // Utilise les valeurs pour les barres
                },
            ],
            tooltip: {
                formatter: function () {
                    const percentageGlobal = (this.y / total) * 100
                    return (
                        this.x + // Utilisation correcte du nom de l'article pour le tooltip
                        ': <b>' +
                        this.y.toLocaleString('fr-FR', {
                            style: 'currency',
                            currency: 'EUR',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        }) +
                        ' - ' +
                        percentageGlobal.toFixed(1) +
                        '%</b>'
                    )
                },
            },
            legend: {
                enabled: false, // Désactive l'affichage de la légende
            },
            credits: {
                enabled: false,
            },
            exporting: {
                enabled: true,
            },
        })
    }
}
