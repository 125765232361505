import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="photo-upload"
export default class extends Controller {
  static targets = ["photos"]

  connect() {
    this.photosTarget.addEventListener("change", this.checkFileLimit.bind(this))
  }

  checkFileLimit(event) {
    const files = event.target.files
    const maxFiles = 30
    const maxMb = 2
    const maxSizeInBytes = maxMb * 1024 * 1024 // 5MB

    if (files.length > maxFiles) {
      alert(`You can upload a maximum of ${maxFiles} files at a time.`)
      event.target.value = "" // Reset the input field
    }

    for (let i = 0; i < files.length; i++) {
      if (files[i].size > maxSizeInBytes) {
        alert(`The file ${files[i].name} exceeds the maximum size of ${maxMb}MB.`)
        event.target.value = "" // Reset the input field
        return
      }
    }
  }
}
