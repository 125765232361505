import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="modal-closer"
export default class extends Controller {
    closeModal(event) {
        // put dataset redirect_url in link (and same place put the controller)
        // data: { redirect_url: ..._path(@...),controller: "modal-closer"
        const redirectUrl = event.currentTarget.dataset.redirectUrl

        window.location.href = redirectUrl
    }
}
