import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="spinner"
export default class extends Controller {
  static targets = ["spinnerContainer"]

  connect() {
    this.observeTurboFrame()
  }

  observeTurboFrame() {
    const frameValue = this.spinnerContainerTarget.dataset.frameValue
    const turboFrame = document.querySelector('turbo-frame#' + frameValue)
    if (turboFrame) {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === 'attributes' && mutation.attributeName === 'busy') {
            this.toggleSpinner(turboFrame.hasAttribute('busy'))
          }
        })
      })

      observer.observe(turboFrame, { attributes: true })
    }
  }

  toggleSpinner(show) {
    this.spinnerContainerTarget.classList.toggle('d-none', !show)
    this.spinnerContainerTarget.classList.toggle('d-flex', show)
  }
}
