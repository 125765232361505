import { Controller } from '@hotwired/stimulus'
import { I18n } from 'i18n-js'
import translations from '../translations.json'

const i18n = new I18n(translations)

const userLocale = document
    .querySelector("meta[name='user-locale']")
    .getAttribute('content')
i18n.locale = userLocale

// Connects to data-controller="chart-losses"
export default class extends Controller {
    connect() {
        console.log('highcharts turnover')
        this.loadChart()
    }

    loadChart() {
        const chartData = this.element.querySelector('#chart-data')
        const categoriesData = JSON.parse(chartData.getAttribute('data-categories'))
        const seriesData = JSON.parse(chartData.getAttribute('data-series'))
        const topArticlesData = JSON.parse(chartData.getAttribute('data-top-articles'))
        const filterPercentageLoss = JSON.parse(chartData.getAttribute('data-filter-percentage'))
        const totalsByRestaurant = JSON.parse(chartData.getAttribute('data-total-in-report'))

        let yAxisTitle = filterPercentageLoss ? i18n.t('i18njs.Percentage Loss (%)') : i18n.t('i18njs.Total Quantity');

        Highcharts.chart('chart-data', {
            chart: {
                type: 'column',
            },
            title: {
                text: null,
            },
            xAxis: {
                categories: categoriesData,
                crosshair: true,
            },
            yAxis: {
                min: 0,
                title: {
                    text: yAxisTitle,
                },
            },
            tooltip: {
                useHTML: true,
                shared: false,
                formatter: function () {
                    let tooltipHtml = `<span style="font-size:10px">${this.key}</span><br/><table class="table table-striped table-bordered table-hover table-sm">`
                    tooltipHtml += `<thead><tr><th>Article</th><th>${i18n.t('i18njs.Quantity')}</th><th>${i18n.t('i18njs.Cost sales')} (€)</th><th>${i18n.t('i18njs.Cost purch')} (€)</th></tr></thead><tbody>`

                    const topArticles = topArticlesData.find((data) => data.name === this.key)?.articles

                    if (topArticles && topArticles.length > 0) {
                        let totalQuantity = 0;
                        let totalTurnoverLost = 0;
                        let totalCostLost = 0;

                        topArticles.forEach((article) => {
                            totalQuantity += article.quantity;
                            const turnoverLost = parseFloat(article.turnover_lost) || 0;
                            const costLost = parseFloat(article.cost_lost) || 0;

                            totalTurnoverLost += turnoverLost;
                            totalCostLost += costLost;

                            const valueTurnoverLost = article.turnover_lost
                                ? Math.floor(article.turnover_lost / 100).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 })
                                : 'NA';
                            const valueCostLost = article.cost_lost
                                ? Math.floor(article.cost_lost / 100).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 })
                                : 'NA';
                            tooltipHtml += `<tr><td>${article.name}</td><td>${article.quantity}</td><td>${valueTurnoverLost}</td><td>${valueCostLost}</td></tr>`;
                        });

                        const formattedTotalTurnoverLost = Math.floor(totalTurnoverLost / 100).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 });
                        const formattedTotalCostLost = Math.floor(totalCostLost / 100).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 });

                        tooltipHtml += `<tr><td><strong>Total</strong></td><td><strong>${totalQuantity}</strong></td><td><strong>${formattedTotalTurnoverLost}</strong></td><td><strong>${formattedTotalCostLost}</strong></td></tr>`;
                    } else {
                        tooltipHtml += `<tr><td colspan="4">${i18n.t('i18njs.No data available')}</td></tr>`
                    }

                    tooltipHtml += '</tbody></table>'
                    return tooltipHtml
                },
            },

            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        formatter: function () {
                            const restaurantName = this.key;
                            const restaurantTotal = totalsByRestaurant[restaurantName] ? parseFloat(totalsByRestaurant[restaurantName].vat_included) : 0;

                            if (filterPercentageLoss) {
                                // Formater le pourcentage de perte
                                const percentageLoss = this.y;
                                let formattedPercentageLoss = percentageLoss > 10 ? percentageLoss.toFixed(0) : percentageLoss.toFixed(1);
                                return `${formattedPercentageLoss} %<br>${i18n.t('i18njs.Sales')}: ${Math.floor(restaurantTotal/100).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 })}`;
                            } else {
                                // Afficher la quantité
                                const quantity = `${this.y} ${i18n.t('i18njs.pces')}`; ;
                                return `${quantity}<br>${i18n.t('i18njs.Sales')}: ${(Math.floor(restaurantTotal / 100)).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 })}`;

                            }
                        }
                    }
                },
            },
            series: [
                {
                    name: filterPercentageLoss ? i18n.t('i18njs.Percentage Loss') : i18n.t('i18njs.Quantity by restaurant'),
                    data: filterPercentageLoss ? this.calculatePercentageLoss(seriesData, categoriesData, totalsByRestaurant) : seriesData,
                },
            ],
            credits: {
                enabled: false,
            },
        })
    }

    calculatePercentageLoss(seriesData, categoriesData, totalsByRestaurant) {
        return seriesData.map((quantity, index) => {
            const restaurantName = categoriesData[index];
            const restaurantTotal = totalsByRestaurant[restaurantName] ? parseFloat(totalsByRestaurant[restaurantName].vat_included) : 0;
            const totalTurnoverLost = totalsByRestaurant[restaurantName] ? parseFloat(totalsByRestaurant[restaurantName].total_turnover_lost) : 0;
            return restaurantTotal ? (totalTurnoverLost / restaurantTotal) * 100 : 0;
        });
    }
}
